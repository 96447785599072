import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { events, getSourceIDFromPathname } from '../../analytics/events'
import { getSubscriptionPreferences } from 'utils/subscriptions'
import { onChatStart, setChatUserDetails } from 'utils/zendesk'
import { track } from 'utils/analytics'
import { UserTerm } from 'types/internal'

import { useHasPastOrders } from 'hooks/orderHistory'
import { useOverlays } from 'contexts/overlays'
import { useUser } from 'contexts/user'
import AccountPage from './account/AccountPage'
import EditTermStatusSidebar from './EditTermStatusSidebar'
import Header from '../common/Header'
import MealPlanPreferencesModal from './MealPlanPreferencesModal'
import MenuPage from './menu/MenuPage'
import MyOrdersPage from './myOrders/MyOrdersPage'
import NotFoundPage from './NotFoundPage'
import UserLoader from 'components/common/UserLoader'
import ReferralModal from './ReferralModal'

const CorePages = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    onChatStart(() => {
      track(events.INITIATE_CHAT, {
        source_id: getSourceIDFromPathname(pathname),
      })
    })
  }, [pathname])

  return (
    <>
      <div>
        <Header />

        <UserLoader>
          <CoreLoaded />
        </UserLoader>
      </div>
    </>
  )
}

export default CorePages

const CoreLoaded = () => {
  const { user } = useUser()

  const { isReferralModalOpen, setIsReferralModalOpen } = useOverlays()

  const [editingTermStatus, setEditingTermStatus] = useState(false)
  const [termStatusToEdit, setTermStatusToEdit] = useState<UserTerm | null>(
    null
  )
  const [showMealPlanPreferencesModal, setShowMealPlanPreferencesModal] =
    useState(false)

  const planPreferences = getSubscriptionPreferences({ user })
  const hasPastOrders = useHasPastOrders({ userID: user.id })

  useEffect(() => {
    if (user.info.email) {
      const email = user.info.email
      const name = user.info.name
      const phone = user.shippingAddresses[0]?.phone

      setChatUserDetails({ email, name, phone })
    }
  }, [user])

  useEffect(() => {
    // Show preference modal if user has not selected preferences yet and
    // has past orders so we don't show this immediately after checkout
    if (
      user.subscription.status === 'active' &&
      user.subscription.isDoNotReplace === null &&
      hasPastOrders
    ) {
      setShowMealPlanPreferencesModal(true)
    }
  }, [hasPastOrders, user])

  const onEditTermStatus = (term: UserTerm) => {
    setTermStatusToEdit(term)
    setEditingTermStatus(true)
  }

  return (
    <>
      <Routes>
        <Route
          element={
            <MenuPage isCurrentTerm onEditTermStatus={onEditTermStatus} />
          }
          path="menu/current"
        />
        <Route
          element={<MenuPage onEditTermStatus={onEditTermStatus} />}
          path="menu/:termID"
        />
        <Route
          element={<MyOrdersPage onEditTermStatus={onEditTermStatus} />}
          path="my-orders"
        />
        <Route
          element={<AccountPage onEditTermStatus={onEditTermStatus} />}
          path="account/*"
        />

        {/* Redirects */}
        <Route element={<Navigate replace to="/menu/current" />} path="/menu" />
        <Route
          element={<Navigate replace to="/menu/current" />}
          path="/account/meals"
        />
        <Route
          element={<Navigate replace to="/my-orders" />}
          path="/account/my-orders"
        />
        <Route element={<Navigate to="/my-orders" />} path="/mapp/menu" />
        <Route element={<Navigate to="my-orders" />} path="/" />

        <Route element={<NotFoundPage />} path="*" />
      </Routes>

      {editingTermStatus && termStatusToEdit && (
        <EditTermStatusSidebar
          onClose={() => setEditingTermStatus(false)}
          selectedUserTerm={termStatusToEdit}
        />
      )}

      {showMealPlanPreferencesModal && planPreferences && (
        <MealPlanPreferencesModal
          closeModal={() => setShowMealPlanPreferencesModal(false)}
          planPreferences={planPreferences}
        />
      )}

      {isReferralModalOpen && (
        <ReferralModal
          onCloseModal={() => setIsReferralModalOpen(false)}
          user={user}
        />
      )}
    </>
  )
}
