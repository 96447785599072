import { ReactNode } from 'react'

const MenuUnavailable = ({ menuHeader }: { menuHeader: ReactNode }) => {
  return (
    <div className="mx-auto flex h-[calc(100vh-200px)] max-w-menu flex-col">
      <div className="pb-4 pt-10 lg:px-4 md:border-t md:border-grey-3 md:py-4">
        {menuHeader}
      </div>

      <div className="flex flex-grow items-center px-20 text-center md:px-4">
        <div>
          <h2 className="mb-4 text-k/36_110 md:mb-2 md:text-k/20_110">
            Menu coming soon.
            <br />
            Stay tuned.
          </h2>
          <p className="mb-10 text-k/20_125 text-grey-10 md:mb-6 md:text-k/18_120 md:text-grey-8">
            For new customers, we'll send you an email as soon as the new menu
            is available so you can pick your first meals!
          </p>
        </div>
      </div>
    </div>
  )
}

export default MenuUnavailable
