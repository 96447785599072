import { Listing } from '@tovala/browser-apis-combinedapi'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import { useUserMeal } from './combinedAPI/meals'
import { useAuth } from 'contexts/auth'
import { useMenuProductDetailsJSON } from '@tovala/browser-apis-cdn'
import { ListingFull } from 'types/internal'

type OpenDetailsProps =
  | {
      type: 'listing'
      id: string
    }
  | {
      type: 'meal'
      id: number
    }

export function useDetailsDialog({
  listings,
  termID,
}: {
  listings?: Listing[]
  termID: number | undefined
}) {
  const { user } = useAuth()
  const userID = user?.id

  const [searchParams, setSearchParams] = useSearchParams()

  const [listingID, setListingID] = useState<string | undefined>()
  const [mealID, setMealID] = useState<number | undefined>(() => {
    const mealSearchParam = searchParams.get('meal')

    return mealSearchParam ? Number(mealSearchParam) : undefined
  })
  const [type, setType] = useState<'listing' | 'meal' | undefined>(() => {
    const mealSearchParam = searchParams.get('meal')

    return mealSearchParam ? 'meal' : undefined
  })

  const { data: userMeal } = useUserMeal({
    mealID,
    userID,
  })

  const listing = listings?.find((listing) => listing.id === listingID)
  const { data: listingDetails } = useMenuProductDetailsJSON({
    productDetailsURL: listing?.productDetailsURL,
  })
  const listingFull: Listing | ListingFull | undefined =
    listing && listingDetails
      ? {
          ...listingDetails,
          ...listing,
        }
      : listing

  const closeDetailsDialog = () => {
    setListingID(undefined)
    setMealID(undefined)
    setType(undefined)

    if (searchParams.get('meal')) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete('meal')
      setSearchParams(newSearchParams)
    } else if (searchParams.get('extra') || searchParams.get('menu')) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete('extra')
      newSearchParams.delete('menu')
      setSearchParams(newSearchParams)
    }
  }

  const openDetailsDialog = ({ type, id }: OpenDetailsProps) => {
    if (type === 'listing') {
      setListingID(id)
      setType('listing')
    } else if (type === 'meal') {
      setMealID(id)
      setType('meal')

      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('meal', `${id}`)
      setSearchParams(newSearchParams)

      track(events.OPENS_MEAL_DETAIL, {
        meal_id: id,
        term_id: termID ?? '',
      })
    }
  }

  useEffect(() => {
    const mealSearchParam = searchParams.get('meal')

    if (mealSearchParam) {
      setMealID(Number(mealSearchParam))
      setType('meal')
    } else if (!mealSearchParam && mealID) {
      setMealID(undefined)
      setType(undefined)
    }
  }, [mealID, searchParams])

  return {
    closeDetailsDialog,
    listing: listingFull,
    meal: userMeal?.meal,
    mealID,
    openDetailsDialog,
    detailsType: type,
  }
}
