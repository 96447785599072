import { Button, ButtonLoading } from '@tovala/component-library'
import { compact } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useMobileAppConfiguration } from '@tovala/browser-apis-cdn'
import { useSendReferralEmail, UserV1 } from '@tovala/browser-apis-combinedapi'

import {
  ErrorCodeMessageMapCombinedAPI,
  GoodErrorMessaging,
} from 'types/internal'
import { events } from '../../analytics/events'
import { track } from 'utils/analytics'
import { wrapWithContactSupportTeam } from 'utils/errors'

import { useToast } from 'contexts/toast'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ErrorDisplay from 'components/common/ErrorDisplay'
import FormGroup from 'components/common/FormGroup'
import Input from 'components/common/Input'
import Modal, { ModalHeader } from 'components/common/Modal'
import Tooltip from 'components/common/Tooltip'
import XTwitterIcon from 'components/common/icons/XTwitterIcon'
import FacebookIcon from 'components/common/icons/FacebookIcon'
import MailIcon from 'components/common/icons/MailIcon'

// found here: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const SEND_REFERRAL_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't send invitations due to a technical issue on our end.",
  },
  EmailInvalid: {
    helpToFix: 'Please make sure the email you entered is valid and try again.',
    why: "We couldn't send an invitation because the email entered does not appear valid.",
  },
}

const ReferralModal = ({
  onCloseModal,
  user,
}: {
  onCloseModal(): void
  user: UserV1
}) => {
  const { openToast } = useToast()

  const [copiedReferralLink, setCopiedReferralLink] = useState(false)
  const [copyError, setCopyError] = useState<GoodErrorMessaging | null>(null)
  const [email, setEmail] = useState({ error: '', value: '' })
  const [shareMessage, setShareMessage] = useState('')

  const referralURL = `${window.location.origin}/referral/${user.referralCode.code}`

  const { data: mobileAppConfiguration } = useMobileAppConfiguration({
    onSuccess: (data) => {
      const { share_dialog_messages, share_dialog_suffix } =
        data.referral_offer || {}

      if (share_dialog_messages && share_dialog_suffix) {
        const randomMessage =
          share_dialog_messages[
            Math.floor(Math.random() * share_dialog_messages.length)
          ]

        setShareMessage(`${randomMessage} ${share_dialog_suffix}`)
      }
    },
    refetchOnWindowFocus: false,
  })

  const { referral_offer: referralOffer } = mobileAppConfiguration || {}

  const {
    error: sendReferralEmailError,
    isError: hasSendReferralEmailError,
    isLoading: isSendingReferralEmail,
    mutate: sendReferralEmail,
  } = useSendReferralEmail({
    onSuccess: () => {
      track(events.USER_SENT_REFERRAL_BY_EMAIL)

      onCloseModal()

      openToast({
        heading: 'Email Sent',
        message: 'Your referral has been sent, thanks for sharing the love!',
        type: 'success',
      })
    },
  })

  const onCopyReferralLink = () => {
    setCopyError(null)

    navigator.clipboard.writeText(referralURL).then(
      () => {
        setCopiedReferralLink(true)
      },
      () => {
        setCopyError({
          helpToFix: 'Please try again or manually copy the referral link.',
          wayOut: wrapWithContactSupportTeam('If you need further assistance'),
          why: "We couldn't copy the referral link because your browser might not support this functionality.",
        })
      }
    )
  }

  const onSendReferral = () => {
    if (EMAIL_REGEX.test(email.value)) {
      sendReferralEmail({
        data: { receiverEmail: email.value },
        userID: user.id,
      })
    } else {
      setEmail((email) => {
        return { ...email, error: 'Please enter a valid email address.' }
      })
    }
  }

  useEffect(() => {
    let timeoutID: number | undefined

    if (copiedReferralLink) {
      timeoutID = window.setTimeout(() => {
        setCopiedReferralLink(false)
      }, 3000)
    }

    return () => {
      window.clearTimeout(timeoutID)
    }
  }, [copiedReferralLink])

  const copyReferralLinkButton = (
    <Button
      buttonStyle="stroke"
      onClick={() => {
        onCopyReferralLink()
      }}
      size="small"
      type="button"
    >
      Copy
    </Button>
  )

  return (
    <Modal onCloseModal={onCloseModal}>
      <div className="w-[500px] sm:w-full">
        <ModalHeader onClickClose={onCloseModal}>Tovala Referral</ModalHeader>

        <div className="space-y-6 px-6 py-10 text-center sm:px-4 sm:py-6">
          <h3 className="text-k/28_130 sm:text-k/24_120">
            {referralOffer?.nav_subtitle ?? (
              <span>
                Share the ultimate <br /> dinnertime de-stressor.
              </span>
            )}
          </h3>

          <div className="space-y-6 text-k/16_125 text-grey-8 sm:space-y-4 sm:text-k/14_120">
            {referralOffer
              ? compact(referralOffer.referral_main_subtitle?.split('\n')).map(
                  (text, i) => {
                    return <p key={i}>{text}</p>
                  }
                )
              : null}
          </div>

          <div className="space-y-2">
            <div className="flex flex-wrap items-center space-x-4 sm:space-x-0 sm:space-y-3">
              <div className="grow sm:w-full">
                <FormGroup error={email.error}>
                  <Input
                    hasError={!!email.error}
                    onChange={(e) =>
                      setEmail((email) => {
                        return { ...email, value: e.target.value }
                      })
                    }
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSendReferral()
                      }
                    }}
                    placeholder="Friend's Email Address"
                    type="email"
                    value={email.value}
                  />
                </FormGroup>
              </div>

              <div className="w-32 self-stretch sm:h-9 sm:w-28 sm:text-k/14_120">
                <ButtonLoading
                  isLoading={isSendingReferralEmail}
                  onClick={() => {
                    onSendReferral()
                  }}
                  size="fluid"
                >
                  Invite
                </ButtonLoading>
              </div>
            </div>

            {hasSendReferralEmailError && (
              <APIErrorDisplay
                error={sendReferralEmailError}
                errorCodeMessageMap={SEND_REFERRAL_ERRORS}
              />
            )}
          </div>

          <p className="text-k/14_120 text-grey-8">
            Invite your friends by email or on social.
          </p>

          <SocialShareButtons
            referralUrl={referralURL}
            shareMessage={shareMessage}
          />

          {referralURL && (
            <div className="space-y-4">
              <div className="flex items-center justify-center space-x-4 sm:space-x-3">
                <a
                  className="text-k/14_120 text-orange-1 underline sm:text-k/12_120"
                  href={referralURL}
                  rel="noreferrer"
                  target="_blank"
                >
                  {referralURL}
                </a>

                {copiedReferralLink ? (
                  <Tooltip
                    alwaysShow
                    trigger={<div>{copyReferralLinkButton}</div>}
                  >
                    Copied
                  </Tooltip>
                ) : (
                  copyReferralLinkButton
                )}
              </div>

              {copyError && <ErrorDisplay {...copyError} />}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ReferralModal

function SocialShareButtons({
  referralUrl,
  shareMessage,
}: {
  referralUrl: string
  shareMessage: string
}) {
  return (
    <div className="flex items-center justify-center space-x-6 sm:space-x-4">
      <Email referralUrl={referralUrl} shareMessage={shareMessage} />

      <Facebook referralUrl={referralUrl} />

      <Twitter referralUrl={referralUrl} shareMessage={shareMessage} />
    </div>
  )
}

const Email = ({
  referralUrl,
  shareMessage,
}: {
  referralUrl: string
  shareMessage: string
}) => {
  return (
    <a
      href={`mailto:?body=${shareMessage} ${referralUrl}`}
      onClick={() => track(events.TAPS_SHARE_CODE)}
    >
      <span className="block w-6">
        <MailIcon />
      </span>
    </a>
  )
}

// This Facebook share link only allows a URL
// Could replace it with Share Dialog if we have a Facebook App ID we can use
// https://developers.facebook.com/docs/sharing/reference/share-dialog
const Facebook = ({ referralUrl }: { referralUrl: string }) => {
  return (
    <a
      href={`https://www.facebook.com/sharer/sharer.php?u=${referralUrl}`}
      onClick={() => track(events.TAPS_SHARE_CODE)}
      rel="noreferrer"
      target="_blank"
      title="Share on Facebook"
    >
      <span className="block w-6">
        <FacebookIcon />
      </span>
    </a>
  )
}

const Twitter = ({
  referralUrl,
  shareMessage,
}: {
  referralUrl: string
  shareMessage: string
}) => {
  return (
    <a
      href={`https://twitter.com/share?text=${shareMessage}&url=${referralUrl}`}
      onClick={() => track(events.TAPS_SHARE_CODE)}
      rel="noreferrer"
      target="_blank"
      title="Share on Twitter"
    >
      <span className="block w-6">
        <XTwitterIcon />
      </span>
    </a>
  )
}
