const FacebookIcon = () => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="currentColor"
        height="100%"
        rx="4"
        width="100%"
        x="0.621094"
      />
      <path
        d="M13.179 3.15625L13.1854 5.29246L11.4989 5.28668C11.4989 5.28668 10.8417 5.45356 10.8218 5.87594C10.8019 6.29833 10.7907 7.78771 10.7907 7.78771L13.1509 7.77656L12.823 10.1561L10.7734 10.1726L10.771 15.999L8.31257 15.997L8.31456 10.1563H6.25781V7.79528L8.31456 7.79349L8.29863 5.62562C8.66007 3.492 9.99771 3.16123 13.18 3.15625H13.179Z"
        fill="#F4F4F0"
      />
    </svg>
  )
}

export default FacebookIcon
