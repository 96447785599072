import { useHomeScreenConfig } from '@tovala/browser-apis-cdn'
import { UserV1 } from '@tovala/browser-apis-combinedapi'
import { events } from 'analytics/events'
import Link from 'components/common/Link'
import { useOvenChannel } from 'hooks/ovenChannel'
import { ReactNode } from 'react'
import { track } from 'utils/analytics'
import { formatCentsToDollars } from 'utils/currency'
import { getTotalCreditsCents } from 'utils/user'

const MyOrdersComponents = ({
  fallbackComponent,
  user,
}: {
  fallbackComponent: ReactNode
  user: UserV1
}) => {
  const { data: homeScreenConfig, isLoading } = useHomeScreenConfig()

  const { isNewRetailCustomer, retailDiscountCents } = useRetailDiscount({
    hasPastOrders: false,
    user,
  })

  if (isLoading) {
    return null
  }

  if (
    !homeScreenConfig ||
    !homeScreenConfig.components ||
    homeScreenConfig.components.length === 0
  ) {
    return fallbackComponent
  }

  const {
    components,
    retailBackgroundImageBanner,
    retailFeaturesBanner,
    retailPromoCard,
  } = homeScreenConfig

  return (
    <div className="space-y-4">
      {components.map((component) => {
        if (
          component === 'retailPromoCard' &&
          retailPromoCard &&
          isNewRetailCustomer
        ) {
          return (
            <div key={component} className="lg:px-4">
              <RetailPromoCard
                {...retailPromoCard}
                retailDiscountCents={retailDiscountCents}
              />
            </div>
          )
        } else if (
          component === 'retailBackgroundImageBanner' &&
          retailBackgroundImageBanner
        ) {
          return (
            <div key={component} className="lg:px-4">
              <RetailBackgroundImageBanner {...retailBackgroundImageBanner} />
            </div>
          )
        } else if (
          component === 'retailFeaturesBanner' &&
          retailFeaturesBanner
        ) {
          return (
            <div key={component} className="lg:px-4">
              <RetailFeaturesBanner {...retailFeaturesBanner} />
            </div>
          )
        }
      })}
    </div>
  )
}

export default MyOrdersComponents

const RetailBackgroundImageBanner = ({
  button,
  imageURL,
  subtitle,
  title,
}: {
  button: {
    actionURL: string
    text: string
  }
  imageURL: string
  subtitle: string
  title: string
}) => {
  return (
    <div className="relative h-min min-h-[340px] overflow-hidden rounded-xl">
      <img
        alt=""
        className="absolute h-full w-full object-cover object-center"
        role="presentation"
        src={imageURL}
      />
      <div className="relative z-10 flex min-h-[inherit] flex-col items-start justify-center space-y-6 p-6 md:justify-end md:p-4">
        <div className="space-y-4 text-white">
          <h2 className="max-w-xs whitespace-pre-wrap text-h/38_95 font-semibold md:self-auto">
            {title}
          </h2>
          <p>{subtitle}</p>
        </div>
        <div>
          <Link
            linkStyle="dark"
            linkType="external"
            onClick={() => {
              track(events.ORDERS_LEARN_MORE, {
                variant: 'social',
              })
            }}
            size="medium"
            to={button.actionURL}
          >
            {button.text}
          </Link>
        </div>
      </div>
    </div>
  )
}

const RetailFeaturesBanner = ({
  button,
  features,
  images,
  title,
}: {
  button: {
    actionURL: string
    text: string
  }
  features: string[]
  images: string[]
  title: string
}) => {
  return (
    <div className="space-y-6 rounded-xl bg-grey-3 p-6 md:p-4">
      <div className="space-y-4">
        <div className="flex space-x-2">
          {images.map((imageURL) => {
            return (
              <div
                key={imageURL}
                className="h-[78px] w-[78px] overflow-hidden rounded-full"
              >
                <img alt="" src={imageURL} />
              </div>
            )
          })}
        </div>
        <h2 className="text-k/24_120">{title}</h2>
        <ul>
          {features.map((feature) => {
            return (
              <li key={feature} className="flex items-center space-x-2">
                <div className="h-4 w-4">
                  <CheckBulletIcon />
                </div>
                <div>{feature}</div>
              </li>
            )
          })}
        </ul>
      </div>

      <div className="inline-block">
        <Link
          linkStyle="orange"
          linkType="external"
          onClick={() => {
            track(events.ORDERS_LEARN_MORE, {
              variant: 'educational',
            })
          }}
          size="medium"
          to={button.actionURL}
        >
          {button.text}
        </Link>
      </div>
    </div>
  )
}

const RetailPromoCard = ({
  bannerText,
  button,
  retailDiscountCents,
  title,
}: {
  bannerText: string
  button: {
    actionURL: string
    text: string
  }
  retailDiscountCents: number
  title: {
    text: string
    placeholderDiscount: string
  }
}) => {
  const titleWithDiscount = title.text.replace(
    '$XX',
    formatCentsToDollars(retailDiscountCents, { digits: 0 })
  )

  return (
    <div className="space-y-4 rounded-xl bg-white p-6 md:p-4">
      <div className="inline-block rounded-md bg-[#398356] px-2 py-1 text-h/11_120 font-semibold text-white">
        {bannerText}
      </div>
      <div className="flex items-center justify-between">
        <h2 className="text-k/16_125 font-semibold">{titleWithDiscount}</h2>
        <div>
          <Link
            linkStyle="gray"
            linkType="external"
            onClick={() => {
              track(events.ORDERS_GET_STARTED)
            }}
            size="medium"
            to={button.actionURL}
          >
            {button.text}
          </Link>
        </div>
      </div>
    </div>
  )
}

const CheckBulletIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.95833 17.9167C7.72722 17.9167 6.56426 17.6868 5.46944 17.227C4.3746 16.7672 3.41921 16.1294 2.60325 15.3134C1.78729 14.4975 1.14943 13.5421 0.689667 12.4472C0.229889 11.3524 0 10.1894 0 8.95833C0 7.70978 0.230292 6.54347 0.690875 5.4594C1.15147 4.37533 1.79158 3.42434 2.61119 2.60644C3.43078 1.78853 4.38617 1.14965 5.47733 0.689791C6.5685 0.22993 7.72883 0 8.95833 0C9.84663 0 10.7007 0.120083 11.5206 0.36025C12.3404 0.600403 13.0975 0.952542 13.7917 1.41667L11.8333 3.375C11.4155 3.14379 10.9618 2.96794 10.4721 2.84744C9.98241 2.72692 9.47782 2.66667 8.95833 2.66667C7.20347 2.66667 5.71615 3.27265 4.49635 4.48463C3.27656 5.69658 2.66667 7.18617 2.66667 8.95337C2.66667 10.7206 3.27606 12.2118 4.49485 13.4271C5.71365 14.6424 7.19976 15.25 8.95319 15.25C10.7066 15.25 12.1944 14.6435 13.4167 13.4306C14.6389 12.2177 15.25 10.7269 15.25 8.95833C15.25 8.77028 15.2361 8.5857 15.2083 8.40458C15.1806 8.22347 15.1528 8.04 15.125 7.85417L17.3125 5.6875C17.5208 6.18579 17.6736 6.70845 17.7708 7.25548C17.8681 7.80251 17.9167 8.36522 17.9167 8.9436C17.9167 10.1895 17.6867 11.3564 17.2269 12.4443C16.767 13.5322 16.1281 14.4859 15.3102 15.3055C14.4923 16.1251 13.5413 16.7652 12.4573 17.2258C11.3732 17.6864 10.2069 17.9167 8.95833 17.9167ZM7.75 13.1458L3.91667 9.3125L5.70833 7.5L7.77083 9.5625L16.125 1.20833L17.9167 3L7.75 13.1458Z"
        fill="#FF6A1B"
      />
    </svg>
  )
}

/*
 * New Costco customers get a meal credit after connecting their oven. The current workflow has
 * has a ~5 minute delay between when the oven is connected and when the user account reflects
 * the account balance. If a user connects their oven and immediately goes through the meals only
 * flow, we'll show a placeholder discount that comes from a CDN file (that will also be used on
 * the apps and MyTovala to show the discount on the home screen).
 */

export function useRetailDiscount({
  hasPastOrders,
  user,
}: {
  hasPastOrders: boolean | undefined
  user: UserV1
}) {
  const activeSubscriptionFlag = user.subscription.status === 'active'

  const totalCreditsCents = getTotalCreditsCents({ user })

  const { data: homeScreenConfig } = useHomeScreenConfig()
  const retailDiscountCents =
    totalCreditsCents ||
    (homeScreenConfig?.retailPromoCard?.title.placeholderDiscount
      ? Number.parseInt(
          homeScreenConfig.retailPromoCard.title.placeholderDiscount,
          10
        ) * 100
      : 0)

  const { data: ovenChannelData } = useOvenChannel({ userID: user.id })
  const { ovenChannel } = ovenChannelData

  const isNewCostcoCustomer =
    !activeSubscriptionFlag &&
    ovenChannel?.toLowerCase() === 'costco' &&
    hasPastOrders === false

  return {
    isNewRetailCustomer: isNewCostcoCustomer,
    retailDiscountCents,
  }
}
