const BowlIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.33325 16C6.33325 21.3387 10.6612 25.6666 15.9999 25.6666C21.3387 25.6666 25.6666 21.3387 25.6666 16V14.3333H6.33325V16Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12.6666 14L6.33325 7.66669"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default BowlIcon
