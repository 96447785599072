import { clsx } from 'clsx'
import BowlIcon from 'components/common/icons/BowlIcon'
import CartIcon from 'components/common/icons/CartIcon'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import { AnimatePresence, motion } from 'framer-motion'
import { useScrollDirection } from 'hooks/general'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'
import { ReactNode, useEffect, useState } from 'react'
import StickyHeader from './StickyHeader'
import { track } from 'utils/analytics'
import { AnalyticsEvent, events } from 'analytics/events'

type Tab = 'extras' | 'meals'

const TAB_ICONS: Record<Tab, JSX.Element> = {
  extras: <CartIcon />,
  meals: <BowlIcon />,
}

const TAB_CHANGE_EVENTS: Record<Tab, AnalyticsEvent> = {
  extras: events.TAPPED_MENU_EXTRAS_TAB,
  meals: events.TAPPED_MENU_MEALS_TAB,
}

const MenuTabGroup = ({
  children,
  menuHeader,
  menuTabs,
  tabChangeEventProps,
  totalListingSelections,
}: {
  children: ReactNode
  menuHeader(opts: { isHeaderSticky: boolean }): ReactNode
  menuTabs: MenuTabs
  tabChangeEventProps: {
    maxSelections: number
    selectionsCount: number
    termID: number
  }
  totalListingSelections?: number
}) => {
  const { selectTab, selectedIndex, tabs } = menuTabs

  return (
    <TabGroup
      onChange={(index) => {
        const tab = tabs[index]
        selectTab(tab)

        track(TAB_CHANGE_EVENTS[tab], tabChangeEventProps)
      }}
      selectedIndex={selectedIndex}
    >
      <StickyHeader>
        {({ isHeaderSticky }) => {
          return (
            <div className="relative mx-auto max-w-menu">
              <div
                className={clsx(
                  'relative z-10 bg-grey-0 pb-4 lg:px-4 md:border-t md:border-grey-3 md:py-4',
                  {
                    'pt-6': isHeaderSticky,
                    'pt-10': !isHeaderSticky,
                  }
                )}
              >
                {menuHeader({ isHeaderSticky })}
              </div>

              <AnimatedTabList
                isHeaderSticky={isHeaderSticky}
                menuTabs={menuTabs}
                totalListingSelections={totalListingSelections}
              />
            </div>
          )
        }}
      </StickyHeader>

      <AnimatePresence initial={false}>
        <TabPanels>{children}</TabPanels>
      </AnimatePresence>
    </TabGroup>
  )
}

export default MenuTabGroup

const AnimatedTabList = ({
  isHeaderSticky,
  menuTabs,
  totalListingSelections,
}: {
  isHeaderSticky: boolean
  menuTabs: MenuTabs
  totalListingSelections?: number
}) => {
  const { isVisible, selectedIndex, setIsVisible, tabs } = menuTabs
  const selectedMenuTab = tabs[selectedIndex]

  useEffect(() => {
    setIsVisible(!isHeaderSticky)
  }, [isHeaderSticky, setIsVisible])

  return (
    <motion.div
      animate={isVisible ? 'open' : 'closed'}
      className="absolute w-full overflow-hidden bg-grey-0"
      initial="open"
      variants={{
        open: {
          transform: 'translateY(0%)',
          transition: { duration: 0.35, type: 'spring', bounce: 0 },
        },
        closed: {
          transform: 'translateY(-100%)',
          transition: { duration: 0.35, type: 'spring', bounce: 0 },
        },
      }}
    >
      <TabList>
        {tabs.map((tab) => {
          return (
            <Tab key={tab}>
              <div className="relative">
                <div className="flex items-center justify-center py-3">
                  <div className="mr-1.5 inline h-8 w-8 align-middle">
                    {TAB_ICONS[tab]}
                  </div>
                  <div className="flex-start relative flex text-k/18_120">
                    <span className="first-letter:capitalize">{tab}</span>
                    {tab === 'extras' &&
                    totalListingSelections &&
                    totalListingSelections > 0 ? (
                      <div className="relative -top-3 right-0 ml-1 flex h-6 w-6 items-center justify-center rounded-full bg-orange-1 text-k/16_125 text-white">
                        {totalListingSelections}
                      </div>
                    ) : null}
                  </div>
                </div>
                {tab === selectedMenuTab ? (
                  <motion.div
                    className="absolute bottom-[-1px] left-0 right-0 z-0 h-[3px] bg-orange-1"
                    layoutId="underline"
                    style={{ originY: 'top' }}
                  />
                ) : null}
              </div>
            </Tab>
          )
        })}
      </TabList>
    </motion.div>
  )
}

export const ExtrasTabPanel = ({ children }: { children: ReactNode }) => {
  return (
    <TabPanel key="extras-panel">
      <div className="overflow-hidden">
        <motion.div
          key="extras"
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          initial={{ x: 300, opacity: 0 }}
          transition={{
            x: {
              type: 'spring',
              stiffness: 300,
              damping: 30,
            },
            opacity: { duration: 0.5 },
          }}
        >
          {children}
        </motion.div>
      </div>
    </TabPanel>
  )
}

export const MealsTabPanel = ({ children }: { children: ReactNode }) => {
  return (
    <TabPanel key="meals-panel">
      <div className="overflow-hidden">
        <motion.div
          key="meals"
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 300, opacity: 0 }}
          initial={{ x: -300, opacity: 0 }}
          transition={{
            x: {
              type: 'spring',
              stiffness: 300,
              damping: 30,
            },
            opacity: { duration: 0.5 },
          }}
        >
          {children}
        </motion.div>
      </div>
    </TabPanel>
  )
}

export interface MenuTabs {
  isVisible: boolean
  selectedIndex: number
  selectTab(tab: Tab): void
  setIsVisible(isVisible: boolean): void
  tabs: Tab[]
}

export const useMenuTabs = (): MenuTabs => {
  const scrollDirection = useScrollDirection()

  const [scrollY, positionY] = useVariantByScreenSize<[number, number]>(
    [80, 79],
    {
      md: [64, 63],
    }
  )

  const tabs: Tab[] = ['meals', 'extras']
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    if (scrollDirection === 'up') {
      setIsVisible(true)
    } else if (scrollDirection === 'down') {
      setIsVisible(false)
    }
  }, [scrollDirection, positionY])

  const selectTab = (tab: Tab) => {
    setIsVisible(true)

    const tabIndex = tabs.indexOf(tab)
    setSelectedIndex(tabIndex)

    if (window.scrollY > scrollY) {
      // Reset scroll position to top of meals/extras grid if scrolled enough that header is sticky
      window.scrollTo(0, positionY)
    }
  }

  return {
    isVisible,
    selectedIndex,
    selectTab,
    setIsVisible,
    tabs,
  }
}
