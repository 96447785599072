import { Button, ButtonLoading } from '@tovala/component-library'
import { clsx } from 'clsx'
import {
  TermStatus,
  useInvalidateTermStatuses,
  useSkippedWeeks,
  useUnskipWeek,
  useUserTermStatuses,
} from '@tovala/browser-apis-combinedapi'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import { ErrorCodeMessageMapCombinedAPI, UserTerm } from 'types/internal'
import { getUserTerm } from 'utils/terms'
import { track } from 'utils/analytics'
import { events, sourceIDs } from 'analytics/events'

import { useIsNoMenuTerm } from 'hooks/combinedAPI/meals'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ProfileHeading from './AccountHeading'
import { useFeatures } from 'contexts/features'

const LOAD_UPCOMING_DELIVERIES_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page.',
    why: "We couldn't load your upcoming deliveries due to a technical issue on our end.",
  },
}

const UNSKIP_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't unskip this week due to a technical issue on our end.",
  },
}

const ManageOrders = ({
  onEditTermStatus,
}: {
  onEditTermStatus(term: UserTerm): void
}) => {
  const { user } = useUser()

  const {
    data: termStatuses = [],
    error: loadUserTermStatusesError,
    isError: hasLoadUserTermStatusesError,
  } = useUserTermStatuses({ userID: user.id })

  const { data: skippedWeeks } = useSkippedWeeks({
    userID: user.id,
  })

  return (
    <div className="w-full max-w-account">
      <div className="hidden md:block">
        <ProfileHeading to="/account">Manage Deliveries</ProfileHeading>
      </div>

      <h2 className="mb-6 text-k/28_130 md:hidden">
        Manage Upcoming Deliveries
      </h2>

      <div className="space-y-4 md:mt-6 md:px-4">
        {termStatuses.length > 0 ? (
          termStatuses.map((term) => {
            const userTerm = getUserTerm({
              skippedWeeks,
              term,
              user,
            })

            return (
              <TermOrder
                key={term.termID}
                onEditTermStatus={onEditTermStatus}
                term={userTerm}
              />
            )
          })
        ) : hasLoadUserTermStatusesError ? (
          <APIErrorDisplay
            error={loadUserTermStatusesError}
            errorCodeMessageMap={LOAD_UPCOMING_DELIVERIES_ERRORS}
          />
        ) : null}
      </div>
    </div>
  )
}

export default ManageOrders

const TermOrder = ({
  onEditTermStatus,
  term,
}: {
  onEditTermStatus(term: TermStatus): void
  term: UserTerm
}) => {
  const { user } = useUser()

  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()

  const { isNoMenuTerm } = useIsNoMenuTerm({ term })

  const {
    error: unskipError,
    isError: hasUnskipError,
    isLoading: isUnskippingWeek,
    mutate: unskipWeek,
  } = useUnskipWeek()

  const { showStaticSkipAsNoMenu } = useFeatures()

  return (
    <div className="space-y-4 rounded-lg bg-grey-2 px-6 py-4 md:px-4">
      <div className="flex items-center justify-between">
        <div className="text-k/14_120">
          <h3 className="text-k/18_120">
            {term.selectedSubTerm?.deliveryDate
              ? formatDate(term.selectedSubTerm?.deliveryDate, {
                  format: DATE_FORMATS.DOW_MONTH_FULL_DAY,
                })
              : ''}
          </h3>

          {isNoMenuTerm ||
          (showStaticSkipAsNoMenu &&
            term.termID === 417 &&
            term.isStaticSkipped) ? (
            <div>No Menu This Week</div>
          ) : (
            <>
              {term.isSkipped ? (
                <div>
                  {term.isStaticSkipped ? (
                    <span>Sold Out</span>
                  ) : (
                    <span>Skipped</span>
                  )}
                </div>
              ) : (
                <div>
                  {user.subscription.subscriptionType && (
                    <span
                      className={clsx(
                        !term.selectedSubscriptionTypeIsDefault &&
                          'pr-1 line-through'
                      )}
                    >
                      {user.subscription.subscriptionType.typeName}
                    </span>
                  )}

                  {!term.selectedSubscriptionTypeIsDefault && (
                    <span>{term.subscriptionType?.maxSelections} Meals</span>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        {!term.isStaticSkipped && !isNoMenuTerm && (
          <>
            {term.isSkipped ? (
              <ButtonLoading
                isLoading={isUnskippingWeek}
                onClick={() => {
                  unskipWeek(
                    { data: { termid: term.termID }, userID: user.id },
                    {
                      onSuccess: () => {
                        invalidateUserTermStatuses(user.id)

                        track(events.UNSKIPS_WEEK, {
                          source_id: sourceIDs.SETTINGS,
                          term_id: term.termID,
                        })
                      },
                    }
                  )
                }}
                size="small"
              >
                Unskip
              </ButtonLoading>
            ) : (
              <Button
                buttonStyle="stroke"
                onClick={() => {
                  onEditTermStatus(term)
                  track(events.SKIP_ADJUST_CTA, {
                    source_id: sourceIDs.SETTINGS,
                  })
                }}
                size="small"
              >
                Skip/Adjust
              </Button>
            )}
          </>
        )}
      </div>

      {hasUnskipError && (
        <APIErrorDisplay
          error={unskipError}
          errorCodeMessageMap={UNSKIP_ERRORS}
        />
      )}
    </div>
  )
}
