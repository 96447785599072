import { ReactNode, useEffect, useRef, useState } from 'react'

function StickyHeader({
  children,
}: {
  children(opts: { isHeaderSticky: boolean }): ReactNode
}) {
  const { elementRef, isStuck } = useHeaderIsStuck<HTMLDivElement>()

  return (
    <div ref={elementRef} className="sticky top-0 z-10 bg-grey-0">
      {children({ isHeaderSticky: isStuck })}
    </div>
  )
}

export default StickyHeader

export function useHeaderIsStuck<RefElementType extends HTMLElement>() {
  const ref = useRef<RefElementType | null>(null)
  const [isStuck, setIsStuck] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio < 1) {
          setIsStuck(true)
        } else {
          setIsStuck(false)
        }
      },
      {
        // Triggers the intersection earlier to prevent juddering when header styles change when stuck
        rootMargin: '-20px 0px 0px 0px',
        threshold: [1],
      }
    )

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return { elementRef: ref, isStuck }
}
