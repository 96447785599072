import { assign, createMachine } from 'xstate'
import { findLastIndex } from 'lodash-es'
import {
  AddListingSelectionResponse,
  DeleteListingSelectionResponse,
  TermStatus,
} from '@tovala/browser-apis-combinedapi'

import {
  AddMealSelectionResponse,
  DeleteMealSelectionResponse,
  UpdateMealSelectionOptionResponse,
} from 'services/combinedAPI/meals'
import { AnalyticsEvent, events } from 'analytics/events'

export function createMenuMachine({
  termID,
  userID,
}: {
  termID: number
  userID: number
}) {
  return createMachine(
    {
      tsTypes: {} as import('./menu.typegen').Typegen0,
      schema: {
        context: {} as {
          addMealMeta:
            | {
                mealID: number
                mealSelectionEvent: AnalyticsEvent
              }
            | undefined
          addMealOptionMeta:
            | {
                mealID: number
                mealOption: string
              }
            | undefined
          nextMaxSelections: number | undefined
          nextPendingMealIndex: number
          pendingMealIDs: number[]
          removeMealMeta:
            | {
                maxSelections: number
                mealID: number
                mealSelectionEvent: AnalyticsEvent
              }
            | undefined
          removeMealOptionMeta:
            | {
                mealID: number
                mealOption: string
              }
            | undefined
          termID: number
          userID: number
        },
        events: {} as
          | {
              listingID: string
              type: 'ADD_LISTING'
            }
          | {
              mealID: number
              mealSelectionEvent: AnalyticsEvent
              nextMaxSelections: number | undefined
              type: 'ADD_MEAL'
            }
          | {
              mealID: number
              mealOption: string
              type: 'ADD_MEAL_OPTION'
            }
          | { type: 'ALL_MEALS_SELECTED' }
          | { type: 'CANCEL_UPGRADE' }
          | {
              listingID: string
              type: 'REMOVE_LISTING'
            }
          | {
              maxSelections: number
              mealID: number
              mealSelectionEvent: AnalyticsEvent
              // We could have an undefined selectionID if the user is removing a meal
              // that they haven't yet actually selected and is only pending in the upgrade
              // queue.
              selectionID: string | undefined
              type: 'REMOVE_MEAL'
            }
          | {
              mealID: number
              mealOption: string
              type: 'REMOVE_MEAL_OPTION'
            }
          | { type: 'SAVE_PENDING_MEALS' }
          | {
              nextMaxSelections: number | undefined
              type: 'UPGRADE'
            },
        services: {} as {
          addListing: { data: AddListingSelectionResponse }
          addMeal: { data: AddMealSelectionResponse }
          addMealOption: { data: UpdateMealSelectionOptionResponse }
          removeListing: { data: DeleteListingSelectionResponse }
          removeMeal: { data: DeleteMealSelectionResponse }
          removeMealOption: { data: UpdateMealSelectionOptionResponse }
          savePendingMeal: { data: AddMealSelectionResponse }
          updateTermStatus: { data: TermStatus }
        },
      },
      predictableActionArguments: true,
      context: {
        addMealMeta: undefined,
        addMealOptionMeta: undefined,
        nextPendingMealIndex: 0,
        nextMaxSelections: undefined,
        pendingMealIDs: [],
        removeMealMeta: undefined,
        removeMealOptionMeta: undefined,
        termID,
        userID,
      },
      id: 'menuMachine',
      initial: 'checkingSelectionsState',
      states: {
        checkingSelectionsState: {
          always: [
            {
              cond: 'hasSelectedAllMeals',
              target: 'mealsSelected',
            },
            { target: 'idle' },
          ],
        },
        idle: {
          on: {
            ADD_LISTING: { target: 'addingListing' },
            ADD_MEAL: { actions: ['storeAddMealMeta'], target: 'addingMeal' },
            ADD_MEAL_OPTION: {
              actions: ['storeAddMealOptionMeta'],
              target: 'addingMealOption',
            },
            ALL_MEALS_SELECTED: { target: 'mealsSelected' },
            REMOVE_LISTING: { target: 'removingListing' },
            REMOVE_MEAL: {
              actions: ['storeRemoveMealMeta'],
              target: 'removingMeal',
            },
            REMOVE_MEAL_OPTION: {
              actions: ['storeRemoveMealOptionMeta'],
              target: 'removingMealOption',
            },
          },
        },
        addingListing: {
          invoke: {
            src: 'addListing',
            onDone: [
              {
                target: '#menuMachine.upgrading.selectingUpgrades',
                cond: 'hasPendingMealIDs',
              },
              {
                target: '#menuMachine.checkingSelectionsState',
              },
            ],
            onError: [
              {
                actions: ['showAddListingError'],
                target: '#menuMachine.upgrading.selectingUpgrades',
                cond: 'hasPendingMealIDs',
              },
              {
                actions: ['showAddListingError'],
                target: '#menuMachine.checkingSelectionsState',
              },
            ],
          },
        },
        addingMeal: {
          invoke: {
            src: 'addMeal',
            onDone: [
              {
                actions: [
                  'trackMealAdded',
                  'trackFinishedMealSelection',
                  'clearAddMealMeta',
                ],
                cond: 'hasMaxMeals',
                target: 'mealsSelected',
              },
              {
                actions: ['trackMealAdded', 'clearAddMealMeta'],
                target: 'idle',
              },
            ],
            onError: { actions: ['showAddMealError'], target: 'idle' },
          },
        },
        addingMealOption: {
          invoke: {
            src: 'addMealOption',
            onDone: [
              {
                actions: ['trackMealOptionAdded', 'clearAddMealOptionMeta'],
                cond: 'hasSelectedAllMeals',
                target: 'mealsSelected',
              },
              {
                actions: ['trackMealOptionAdded', 'clearAddMealOptionMeta'],
                target: 'idle',
              },
            ],
            onError: [
              {
                actions: ['showAddOptionError'],
                cond: 'hasSelectedAllMeals',
                target: 'mealsSelected',
              },
              {
                actions: ['showAddOptionError'],
                target: 'idle',
              },
            ],
          },
        },
        removingListing: {
          invoke: {
            src: 'removeListing',
            onDone: [
              {
                target: '#menuMachine.upgrading.selectingUpgrades',
                cond: 'hasPendingMealIDs',
              },
              {
                target: '#menuMachine.checkingSelectionsState',
              },
            ],
            onError: [
              {
                actions: ['showRemoveListingError'],
                target: '#menuMachine.upgrading.selectingUpgrades',
                cond: 'hasPendingMealIDs',
              },
              {
                actions: ['showRemoveListingError'],
                target: '#menuMachine.checkingSelectionsState',
              },
            ],
          },
        },
        removingMeal: {
          invoke: {
            src: 'removeMeal',
            onDone: {
              actions: ['trackMealRemoved', 'clearRemoveMealMeta'],
              target: 'idle',
            },
            onError: { actions: ['showRemoveMealError'], target: 'idle' },
          },
        },
        removingMealOption: {
          invoke: {
            src: 'removeMealOption',
            onDone: [
              {
                actions: [
                  'trackMealOptionRemoved',
                  'clearRemoveMealOptionMeta',
                ],
                cond: 'hasSelectedAllMeals',
                target: 'mealsSelected',
              },
              {
                actions: [
                  'trackMealOptionRemoved',
                  'clearRemoveMealOptionMeta',
                ],
                target: 'idle',
              },
            ],
            onError: [
              {
                actions: ['showRemoveOptionError'],
                cond: 'hasSelectedAllMeals',
                target: 'mealsSelected',
              },
              {
                actions: ['showRemoveOptionError'],
                target: 'idle',
              },
            ],
          },
        },
        mealsSelected: {
          on: {
            ADD_LISTING: { target: 'addingListing' },
            ADD_MEAL: {
              actions: ['addToPendingMealIDs', 'storeNextMaxSelections'],
              cond: 'hasFewerThanMaxMeals',
              target: 'upgrading',
            },
            ADD_MEAL_OPTION: {
              actions: ['storeAddMealOptionMeta'],
              target: 'addingMealOption',
            },
            REMOVE_LISTING: { target: 'removingListing' },
            REMOVE_MEAL: {
              actions: ['storeRemoveMealMeta'],
              target: 'removingMeal',
            },
            REMOVE_MEAL_OPTION: {
              actions: ['storeRemoveMealOptionMeta'],
              target: 'removingMealOption',
            },
            UPGRADE: {
              actions: ['storeNextMaxSelections'],
              target: 'upgrading',
            },
          },
        },
        upgrading: {
          initial: 'selectingUpgrades',
          states: {
            addingMealOption: {
              initial: 'addingOption',
              states: {
                addingOption: {
                  invoke: {
                    src: 'addMealOption',
                    onDone: [
                      {
                        actions: [
                          'trackMealOptionAdded',
                          'clearAddMealOptionMeta',
                        ],
                        target: '#menuMachine.upgrading.selectingUpgrades',
                      },
                    ],
                    onError: [
                      {
                        actions: ['showAddOptionError'],
                        target: '#menuMachine.upgrading.selectingUpgrades',
                      },
                    ],
                  },
                },
              },
            },
            selectingUpgrades: {
              on: {
                ADD_LISTING: { target: '#menuMachine.addingListing' },
                ADD_MEAL: {
                  actions: ['addToPendingMealIDs'],
                  cond: 'hasFewerMealsThanNextPlanMax',
                },
                ADD_MEAL_OPTION: [
                  {
                    actions: ['addOptionToPendingMealIDs'],
                    cond: 'hasSelectionsOfMeal',
                    target: 'addingMealOption',
                  },
                  {
                    actions: ['addOptionToPendingMealIDs'],
                  },
                ],
                CANCEL_UPGRADE: {
                  actions: ['clearPendingMealIDs', 'clearNextMaxSelections'],
                  target: '#menuMachine.checkingSelectionsState',
                },
                REMOVE_LISTING: { target: '#menuMachine.removingListing' },
                REMOVE_MEAL: [
                  {
                    actions: [
                      'removeFromPendingMealIDs',
                      'clearNextMaxSelections',
                    ],
                    cond: 'isLastMealIDToRemovePending',
                    target: '#menuMachine.checkingSelectionsState',
                  },
                  {
                    actions: ['removeFromPendingMealIDs'],
                    cond: 'isMealIDToRemovePending',
                  },
                  { actions: ['storeRemoveMealMeta'], target: 'removingMeal' },
                ],
                REMOVE_MEAL_OPTION: [
                  {
                    actions: ['removeOptionFromPendingMealIDs'],
                    cond: 'hasSelectionsOfMealOption',
                    target: 'removingMealOption',
                  },
                  {
                    actions: ['removeOptionFromPendingMealIDs'],
                  },
                ],
                SAVE_PENDING_MEALS: {
                  actions: ['trackConfirmedUpgrade'],
                  target: 'savingUpgrades',
                },
              },
            },
            removingMeal: {
              initial: 'removing',
              states: {
                removing: {
                  invoke: {
                    src: 'removeMeal',
                    onDone: [
                      {
                        actions: [
                          'trackMealRemoved',
                          'clearRemoveMealMeta',
                          'setNextPendingMealIndex',
                        ],
                        cond: 'hasPendingMealIDs',
                        target: 'addingMealFromPending',
                      },
                      {
                        actions: ['trackMealRemoved', 'clearRemoveMealMeta'],
                        target: '#menuMachine.idle',
                      },
                    ],
                    onError: {
                      actions: ['showRemoveMealError'],
                      target: '#menuMachine.upgrading.selectingUpgrades',
                    },
                  },
                },
                addingMealFromPending: {
                  entry: ['setAddMealMetaFromPending'],
                  invoke: {
                    src: 'savePendingMeal',
                    onDone: [
                      {
                        actions: [
                          'trackMealAdded',
                          'clearAddMealMeta',
                          'removeFirstPendingMeal',
                          'clearNextMaxSelections',
                        ],
                        cond: 'isLastPendingMeal',
                        target: '#menuMachine.checkingSelectionsState',
                      },
                      {
                        actions: [
                          'trackMealAdded',
                          'clearAddMealMeta',
                          'removeFirstPendingMeal',
                        ],
                        target: '#menuMachine.upgrading.selectingUpgrades',
                      },
                    ],
                    onError: {
                      target: '#menuMachine.upgrading.selectingUpgrades',
                    },
                  },
                },
              },
            },
            removingMealOption: {
              initial: 'removingOption',
              states: {
                removingOption: {
                  invoke: {
                    src: 'removeMealOption',
                    onDone: [
                      {
                        actions: [
                          'trackMealOptionRemoved',
                          'clearRemoveMealOptionMeta',
                        ],
                        target: '#menuMachine.upgrading.selectingUpgrades',
                      },
                    ],
                    onError: [
                      {
                        actions: ['showRemoveOptionError'],
                        target: '#menuMachine.upgrading.selectingUpgrades',
                      },
                    ],
                  },
                },
              },
            },
            savingUpgrades: {
              initial: 'updatingTermStatus',
              states: {
                updatingTermStatus: {
                  invoke: {
                    src: 'updateTermStatus',
                    onDone: {
                      actions: ['setNextPendingMealIndex'],
                      target: 'savingNextPendingMeal',
                    },
                    onError: {
                      target: '#menuMachine.upgrading.selectingUpgrades',
                    },
                  },
                },
                savingNextPendingMeal: {
                  entry: ['setAddMealMetaFromPending'],
                  invoke: {
                    src: 'savePendingMeal',
                    onDone: [
                      {
                        actions: [
                          'trackMealAdded',
                          'clearAddMealMeta',
                          'incrementNextPendingMealIndex',
                        ],
                        cond: 'hasMorePendingMeals',
                        target: 'savingNextPendingMeal',
                      },
                      {
                        actions: [
                          'trackMealAdded',
                          'trackFinishedMealSelection',
                          'clearAddMealMeta',
                          'clearNextMaxSelections',
                          'clearPendingMealIDs',
                          'setNextPendingMealIndex',
                        ],
                        target: '#menuMachine.mealsSelected',
                      },
                    ],
                    onError: {
                      target: '#menuMachine.upgrading.selectingUpgrades',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    {
      actions: {
        addToPendingMealIDs: assign({
          pendingMealIDs: (ctx, event) => {
            return [...ctx.pendingMealIDs, event.mealID]
          },
        }),
        addOptionToPendingMealIDs: assign({
          pendingMealIDs: (ctx, event) => {
            const mealOptionID = Number.parseInt(event.mealOption, 10)

            return ctx.pendingMealIDs.map((pendingMealID) => {
              if (pendingMealID === event.mealID) {
                return mealOptionID
              }
              return pendingMealID
            })
          },
        }),
        clearAddMealMeta: assign({ addMealMeta: undefined }),
        clearAddMealOptionMeta: assign({ addMealOptionMeta: undefined }),
        clearRemoveMealMeta: assign({ removeMealMeta: undefined }),
        clearRemoveMealOptionMeta: assign({ removeMealOptionMeta: undefined }),
        clearPendingMealIDs: assign({
          pendingMealIDs: [],
        }),
        clearNextMaxSelections: assign({
          nextMaxSelections: undefined,
        }),
        incrementNextPendingMealIndex: assign({
          nextPendingMealIndex: (ctx) => {
            return ctx.nextPendingMealIndex + 1
          },
        }),
        removeFirstPendingMeal: assign({
          pendingMealIDs: (ctx) => {
            return ctx.pendingMealIDs.slice(1)
          },
        }),
        removeFromPendingMealIDs: assign({
          pendingMealIDs: (ctx, event) => {
            const newPendingMealIDs = [...ctx.pendingMealIDs]

            const lastIndexOfMealID = findLastIndex(
              newPendingMealIDs,
              (pendingMealID) => {
                return pendingMealID === event.mealID
              }
            )

            if (lastIndexOfMealID !== -1) {
              newPendingMealIDs.splice(lastIndexOfMealID, 1)
            }

            return newPendingMealIDs
          },
        }),
        removeOptionFromPendingMealIDs: assign({
          pendingMealIDs: (ctx, event) => {
            const mealOptionID = Number.parseInt(event.mealOption, 10)
            return ctx.pendingMealIDs.map((pendingMealID) => {
              if (pendingMealID === mealOptionID) {
                return event.mealID
              }
              return pendingMealID
            })
          },
        }),
        setNextPendingMealIndex: assign({
          nextPendingMealIndex: 0,
        }),
        setAddMealMetaFromPending: assign({
          addMealMeta: (ctx) => {
            return {
              mealID: ctx.pendingMealIDs[ctx.nextPendingMealIndex],
              mealSelectionEvent: events.MODIFY_MEAL_SELECTION,
            }
          },
        }),
        storeAddMealMeta: assign({
          addMealMeta: (_ctx, event) => {
            return {
              mealID: event.mealID,
              mealSelectionEvent: event.mealSelectionEvent,
            }
          },
        }),
        storeAddMealOptionMeta: assign({
          addMealOptionMeta: (_ctx, event) => {
            return {
              mealID: event.mealID,
              mealOption: event.mealOption,
            }
          },
        }),
        storeNextMaxSelections: assign({
          nextMaxSelections: (_ctx, event) => {
            return event.nextMaxSelections
          },
        }),
        storeRemoveMealMeta: assign({
          removeMealMeta: (_ctx, event) => {
            return {
              maxSelections: event.maxSelections,
              mealID: event.mealID,
              mealSelectionEvent: event.mealSelectionEvent,
            }
          },
        }),
        storeRemoveMealOptionMeta: assign({
          removeMealOptionMeta: (_ctx, event) => {
            return {
              mealID: event.mealID,
              mealOption: event.mealOption,
            }
          },
        }),
      },
      guards: {
        hasMorePendingMeals: (ctx) => {
          return ctx.nextPendingMealIndex + 1 < ctx.pendingMealIDs.length
        },
        hasPendingMealIDs: (ctx) => {
          return ctx.pendingMealIDs.length > 0
        },
        isLastMealIDToRemovePending: (ctx, event) => {
          return (
            ctx.pendingMealIDs.includes(event.mealID) &&
            ctx.pendingMealIDs.length === 1
          )
        },
        isLastPendingMeal: (ctx) => {
          return ctx.pendingMealIDs.length === 1
        },
        isMealIDToRemovePending: (ctx, event) => {
          return ctx.pendingMealIDs.includes(event.mealID)
        },
      },
    }
  )
}
