import {
  AlertIcon,
  Button,
  CaretDownIcon,
  CaretUpIcon,
  XIcon,
} from '@tovala/component-library'
import { clsx } from 'clsx'
import { Dialog } from '@headlessui/react'
import {
  Listing,
  Meal,
  MealSummary,
  MealTag,
  NutritionalInfoItem,
  OrderHistoryReceiptMeal,
  useIntentRoutine,
} from '@tovala/browser-apis-combinedapi'
import { ReactNode, useState } from 'react'

import { events } from '../../../analytics/events'
import {
  getDisplayTags,
  getFormattedExpirationDate,
  getMealBarcode,
} from 'utils/meals'
import { getFormattedCookTime, getTotalCookTimeInSeconds } from 'utils/routines'
import { track } from 'utils/analytics'

import { useOvens } from 'hooks/combinedAPI/ovens'
import { useUser } from 'contexts/user'
import Collapsible, {
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/common/Collapsible'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationHeader,
} from 'components/common/ConfirmationDialog'
import MealTagsScrolling from './MealTagsScrolling'
import { ListingFull } from 'types/internal'
import { partition } from 'lodash-es'

type SectionID = 'in_the_box' | 'nutrition' | 'prep_steps'

const DetailsDialog = ({
  children,
  onClose,
}: {
  children: ReactNode
  onClose(): void
}) => {
  return (
    <Dialog onClose={onClose} open={true}>
      <div
        className="fixed inset-0 z-30 bg-black opacity-40"
        onClick={onClose}
      />
      <div className="fixed inset-0 z-30 flex justify-center">
        <Dialog.Panel className="flex h-full max-w-[375px] flex-col bg-grey-0">
          <div className="flex justify-end border-b border-grey-3 px-4 py-5">
            <Button
              aria-label="Close"
              buttonStyle="link"
              onClick={onClose}
              size="auto"
            >
              <div className="h-6 w-6">
                <XIcon />
              </div>
            </Button>
          </div>

          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default DetailsDialog

export const ListingDetails = ({
  listing,
}: {
  listing: Listing | ListingFull
}) => {
  const [showIngredientsList, setShowIngredientsList] = useState(false)

  const onCollapsibleClick = (sectionID: SectionID) => {
    track(events.OPENS_EXTRA_DETAIL_DISCLOSURE_TAB, {
      extra_detail_section_id: sectionID,
    })
  }

  if ('subtitle' in listing) {
    return (
      <div className="flex-auto overflow-y-auto">
        <div className="mx-4 my-8">
          <Dialog.Title className="text-k/28_110">{listing.title}</Dialog.Title>
          <p className="text-k/24_120 text-grey-8">{listing.subtitle}</p>
        </div>

        <div className="relative h-[275px] overflow-hidden">
          {listing.imageURL && (
            <img
              alt=""
              className="h-full w-full object-cover"
              src={listing.imageURL}
            />
          )}
        </div>

        <div className="mx-4 my-8">
          <CookTime barcode={listing.barcode} />
        </div>

        <div className="mx-4 mb-16 mt-8">
          <h3 className="mb-4 text-k/20_110">Description</h3>
          <p className="mb-4 text-k/14_120 tracking-normal text-grey-9">
            {listing.story}
          </p>
          {listing.expirationDate && (
            <div className="mb-12 inline-block bg-white px-3 py-1 text-h/11_120 font-semibold uppercase">
              <span className="mr-1 inline-block h-[10px] w-[10px] rounded-full bg-orange-1" />
              Enjoy before {getFormattedExpirationDate(listing.expirationDate)}
            </div>
          )}

          <div className="divide-y divide-grey-4 border-b border-t border-grey-4">
            {listing.nutritionalInfo && (
              <div>
                <DetailsCollapsible
                  onCollapsibleClick={onCollapsibleClick}
                  sectionID="nutrition"
                  title="Nutrition"
                >
                  <div>
                    <Nutrition
                      nutritionalInfo={listing.nutritionalInfo}
                      tags={[]}
                    />

                    {listing.ingredients && (
                      <div className="mt-3">
                        <Button
                          onClick={() => setShowIngredientsList(true)}
                          size="small"
                        >
                          Ingredients List
                        </Button>

                        {showIngredientsList && (
                          <IngredientsListModal
                            closeModal={() => setShowIngredientsList(false)}
                            ingredients={listing.ingredients}
                          />
                        )}
                      </div>
                    )}

                    <div>
                      <h3 className="mb-4 mt-6 text-k/20_125 md:text-k/18_120">
                        Allergens
                      </h3>

                      <div className="flex flex-wrap gap-4">
                        {listing.allergens.map((allergen) => {
                          return (
                            <div key={allergen} className="w-12">
                              <Allergen allergen={allergen} />
                            </div>
                          )
                        })}
                      </div>

                      <p className="mt-4 text-k/14_120 text-grey-9">
                        Produced and packed in a facility that handles milk,
                        wheat, eggs, fish, shellfish, tree nuts, peanuts, soy,
                        and gluten.
                      </p>
                    </div>
                  </div>
                </DetailsCollapsible>
              </div>
            )}

            {listing.componentImages && (
              <div>
                <DetailsCollapsible
                  onCollapsibleClick={onCollapsibleClick}
                  sectionID="in_the_box"
                  title="In the Box"
                >
                  <div>
                    <ComponentImages images={listing.componentImages} />
                  </div>
                </DetailsCollapsible>
              </div>
            )}

            {listing.prepSteps && (
              <div>
                <DetailsCollapsible
                  onCollapsibleClick={onCollapsibleClick}
                  sectionID="prep_steps"
                  title="Preparation Steps"
                >
                  <ol className="space-y-4">
                    {listing.prepSteps.map((step, index) => {
                      return (
                        <li key={step} className="flex">
                          <span className="mr-4 text-k/32_105">
                            {index + 1}.
                          </span>
                          <PreparationStep
                            key={step}
                            step={step.replace(/[0-9]+\./g, '')}
                          />
                        </li>
                      )
                    })}
                  </ol>
                </DetailsCollapsible>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  // Loading listing details
  return (
    <div className="flex-auto overflow-y-auto">
      <div className="mx-4 my-8">
        <Dialog.Title className="text-k/28_110">{listing.title}</Dialog.Title>
      </div>

      <div className="relative h-[275px] overflow-hidden">
        {listing.imageURL && (
          <img
            alt=""
            className="h-full w-full object-cover"
            src={listing.imageURL}
          />
        )}
      </div>
    </div>
  )
}

export const MealDetails = ({
  meal,
  suggestions,
}: {
  meal: Meal | OrderHistoryReceiptMeal
  suggestions?: MealSummary[]
}) => {
  const [showIngredientsList, setShowIngredientsList] = useState(false)

  const barcodeIdentifier = meal.routineMetadataObjects?.find(
    (routine) => routine.isDefaultRoutine
  )?.barcodeIdentifier

  const barcode = barcodeIdentifier
    ? getMealBarcode({ mealID: meal.id, barcodeIdentifier })
    : ''

  const [mainImage, componentImages] = partition(
    meal.images,
    (image) => image.key === 'cell_tile'
  )
  const imageURL = mainImage[0]?.url

  const nutritionNotes = meal.tags.filter(
    (tag) => tag.displayMode === 'nutrition_note'
  )

  let prepSteps: string[] | '' = ''
  let oneLiner = ''
  if (meal.mealPrepSteps) {
    prepSteps = meal.mealPrepSteps.split(/[0-9]+\./g).filter((s) => s)

    if (prepSteps.length > 0) {
      const lastPrepStep = prepSteps[prepSteps.length - 1]
      const oneLinerStartIndex = lastPrepStep.indexOf("You've got Tovala Time.")

      if (oneLinerStartIndex !== -1) {
        prepSteps[prepSteps.length - 1] = lastPrepStep.substring(
          0,
          oneLinerStartIndex
        )
        oneLiner = lastPrepStep.substring(oneLinerStartIndex)
      }
    }
  }

  // For the mealWithExtra component, we will be linking the extra meal details to a termless meal,
  // which cannot have an expiration date due to not being on any menus. Meals without an
  // expiration date set are returned as the string below.
  const showExpirationDate = meal.expirationDate !== '0001-01-01T00:00:00Z'

  const onCollapsibleClick = (sectionID: SectionID) => {
    track(events.OPENS_MEAL_DETAIL_DISCLOSURE_TAB, {
      meal_detail_section_id: sectionID,
    })
  }

  return (
    <div className="flex-auto overflow-y-auto">
      <div className="mx-4 my-8">
        <Dialog.Title className="text-k/28_110">{meal.title}</Dialog.Title>
        <p className="text-k/24_120 text-grey-8">{meal.subtitle}</p>
      </div>
      <div className="relative h-[275px] overflow-hidden">
        {imageURL && (
          <img alt="" className="h-full w-full object-cover" src={imageURL} />
        )}
        {meal.chefImage && (
          <div className="absolute bottom-2 right-2 h-20 w-20 overflow-hidden rounded-full">
            <img alt="" className="w-20" src={meal.chefImage} />
            <div className="absolute bottom-1 h-20 w-20 text-h/14_120 font-semibold uppercase tracking-[4px]">
              <svg className="block overflow-visible" viewBox="0 0 100 100">
                <path d="M0,40 c0,80 100,80 100,0" fill="none" id="curve" />
                <text
                  style={{
                    textAnchor: 'middle',
                    fill: '#FFF',
                    textShadow: '0px 0px 2px #000',
                  }}
                >
                  <textPath startOffset="50%" xlinkHref="#curve">
                    Chef {meal.createdBy}
                  </textPath>
                </text>
              </svg>
            </div>
          </div>
        )}
      </div>

      <div className="my-8 ml-4 flex items-center divide-x divide-grey-4">
        <CookTime barcode={barcode} />

        {meal.tags.length > 0 && (
          <div className="overflow-hidden pl-4">
            <MealTagsScrolling tags={getDisplayTags({ meal, suggestions })} />
          </div>
        )}
      </div>

      <div className="mx-4 mb-16 mt-8">
        <h3 className="mb-4 text-k/20_110">Description</h3>
        <p className="mb-4 text-k/14_120 tracking-normal text-grey-9">
          {meal.story}
        </p>
        {showExpirationDate && (
          <div className="mb-12 inline-block bg-white px-3 py-1 text-h/11_120 font-semibold uppercase">
            <span className="mr-1 inline-block h-[10px] w-[10px] rounded-full bg-orange-1" />
            Enjoy before {getFormattedExpirationDate(meal.expirationDate)}
          </div>
        )}

        <div className="divide-y divide-grey-4 border-b border-t border-grey-4">
          {meal.nutritionalInfo && (
            <div>
              <DetailsCollapsible
                onCollapsibleClick={onCollapsibleClick}
                sectionID="nutrition"
                title="Nutrition"
              >
                <div>
                  {nutritionNotes &&
                    nutritionNotes.map((note) => (
                      <p key={note.id}>{note.title}</p>
                    ))}

                  <Nutrition
                    nutritionalInfo={meal.nutritionalInfo}
                    tags={meal.tags}
                  />

                  {meal.ingredients && (
                    <div className="mt-3">
                      <Button
                        onClick={() => setShowIngredientsList(true)}
                        size="small"
                      >
                        Ingredients List
                      </Button>

                      {showIngredientsList && (
                        <IngredientsListModal
                          closeModal={() => setShowIngredientsList(false)}
                          ingredients={meal.ingredients}
                        />
                      )}
                    </div>
                  )}

                  <div>
                    <h3 className="mb-4 mt-6 text-k/20_125 md:text-k/18_120">
                      Allergens
                    </h3>

                    <div className="flex space-x-4">
                      {meal.nutritionalInfo
                        .filter(({ key }) => key === 'allergen')
                        .map(({ name }) => {
                          return <Allergen key={name} allergen={name} />
                        })}
                    </div>

                    <p className="mt-4 text-k/14_120 text-grey-9">
                      Produced and packed in a facility that handles milk,
                      wheat, eggs, fish, shellfish, tree nuts, peanuts, soy, and
                      gluten.
                    </p>
                  </div>
                </div>
              </DetailsCollapsible>
            </div>
          )}

          {meal.images && (
            <div>
              <DetailsCollapsible
                onCollapsibleClick={onCollapsibleClick}
                sectionID="in_the_box"
                title="In the Box"
              >
                <div>
                  <ComponentImages
                    images={componentImages.map(({ caption, url }) => ({
                      caption,
                      url,
                    }))}
                  />
                </div>
              </DetailsCollapsible>
            </div>
          )}

          {prepSteps && (
            <div>
              <DetailsCollapsible
                onCollapsibleClick={onCollapsibleClick}
                sectionID="prep_steps"
                title="Preparation Steps"
              >
                <ol className="space-y-4">
                  {prepSteps.map((step, index) => {
                    return (
                      <li key={step} className="flex">
                        <span className="mr-4 text-k/32_105">{index + 1}.</span>
                        <PreparationStep key={step} step={step} />
                      </li>
                    )
                  })}
                </ol>
                <div className="mt-4">
                  {oneLiner && <PreparationStep step={oneLiner} />}
                </div>
              </DetailsCollapsible>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const Allergen = ({ allergen }: { allergen: string }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="h-6 w-6">
        <AlertIcon />
      </div>
      <span className="text-center text-k/14_120 text-grey-9">{allergen}</span>
    </div>
  )
}

const ComponentImages = ({
  images,
}: {
  images: { url: string; caption: string }[]
}) => {
  return (
    <div className="space-y-2 text-k/14_120">
      {images.map(({ caption, url }) => {
        return (
          <div key={url} className="flex items-center">
            <div className="w-20 text-center">
              <img alt="" className="inline" src={url} />
            </div>
            <div className="ml-2">{caption}</div>
          </div>
        )
      })}
    </div>
  )
}

const CookTime = ({ barcode }: { barcode: string }) => {
  const { user } = useUser()

  const userID = user.id

  const { data: getOvensResponse } = useOvens({ userID })

  // For the purposes of cook time, we're just taking the user's first oven. For the scope
  // of this work, that's what we want to do. If we needed to show multiple ovens, we'd
  // probably update the UI to allow a user to select one of their ovens.
  const ovenID = getOvensResponse?.[0]?.id

  const { data: getIntentRoutineResponse } = useIntentRoutine({
    ovenID,
    routineBarcode: barcode,
    userID,
  })

  const routineSteps = getIntentRoutineResponse?.routine.routine

  if (!routineSteps) {
    return null
  }

  const formattedCookTime = getFormattedCookTime({
    cookTimeInSeconds: getTotalCookTimeInSeconds({ routineSteps }),
  })

  return (
    <span className="shrink-0 py-1 pr-4 text-k/20_110">
      {formattedCookTime} min
    </span>
  )
}

const DetailsCollapsible = ({
  children,
  onCollapsibleClick,
  sectionID,
  title,
}: {
  children: ReactNode
  onCollapsibleClick(sectionID: SectionID): void
  sectionID: SectionID
  title: string
}) => {
  return (
    <Collapsible>
      {({ open }) => {
        return (
          <>
            <CollapsibleTrigger className="w-full py-4">
              <div
                className="flex items-center justify-between"
                onClick={() => {
                  if (!open) {
                    onCollapsibleClick(sectionID)
                  }
                }}
              >
                <h3 className="text-k/20_125 md:text-k/18_120">{title}</h3>
                {open ? (
                  <div className="h-6 w-6">
                    <CaretUpIcon />
                  </div>
                ) : (
                  <div className="h-6 w-6">
                    <CaretDownIcon />
                  </div>
                )}
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent open={open}>
              <div className="pb-12">{children}</div>
            </CollapsibleContent>
          </>
        )
      }}
    </Collapsible>
  )
}

const IngredientsListModal = ({
  closeModal,
  ingredients,
}: {
  closeModal(): void
  ingredients: Meal['ingredients']
}) => {
  return (
    <ConfirmationDialog onRequestClose={closeModal}>
      <ConfirmationHeader
        heading="Ingredients List"
        onClickClose={closeModal}
      />
      <ConfirmationBody>
        <div className="min-w-[300px] whitespace-pre-wrap py-6 text-left text-k/14_120 capitalize text-black">
          {ingredients}
        </div>
      </ConfirmationBody>
    </ConfirmationDialog>
  )
}

const Nutrition = ({
  nutritionalInfo,
  tags,
}: {
  nutritionalInfo: Partial<NutritionalInfoItem>[]
  tags: MealTag[]
}) => {
  const noteTags = tags.filter((tag) => tag.displayMode === 'nutrition_note')
  const smartpoints = nutritionalInfo.find(({ name }) => name === 'SmartPoints')
  const hasGarnishes = nutritionalInfo.some(({ key }) => key === 'garnish')

  return (
    <div>
      {noteTags && noteTags.map((note) => <p key={note.id}>{note.title}</p>)}

      <div className="divide-y divide-grey-3 border-b border-grey-3">
        {nutritionalInfo
          .filter(({ key }) => key === 'golden' || key === 'sub')
          .map(({ name, value, unit }) => {
            return (
              <NutritionRow key={name} name={name} unit={unit} value={value} />
            )
          })}
      </div>

      {hasGarnishes && (
        <div className="mt-4">
          <h3 className="mb-4 text-k/20_125 md:text-k/18_120">
            Optional Garnishes
          </h3>
          <div className="divide-y divide-grey-3 border-b border-grey-3">
            {nutritionalInfo
              .filter(({ key }) => key === 'garnish')
              .map(({ name, value, unit }) => {
                return (
                  <NutritionRow
                    key={name}
                    name={name}
                    unit={unit && ` ${unit.toLowerCase()}`}
                    value={`+${value}`}
                  />
                )
              })}
          </div>
        </div>
      )}
      {smartpoints && (
        <div className="mt-4">
          <span>{smartpoints.value}</span>
          <span> {smartpoints.name}</span>
        </div>
      )}
    </div>
  )
}

const NutritionRow = ({
  name,
  unit,
  value,
}: {
  name: NutritionalInfoItem['name'] | undefined
  unit: NutritionalInfoItem['unit'] | undefined
  value: NutritionalInfoItem['value'] | undefined
}) => {
  return (
    <div
      className={clsx(
        'flex justify-between py-1 text-k/14_120 tracking-normal text-grey-9',
        name === 'Calories' && 'border-b-2 border-grey-8'
      )}
    >
      <span>{name}</span>
      <span>
        {value}
        {unit}
      </span>
    </div>
  )
}

const PreparationStep = ({ step }: { step: string }) => {
  return (
    <div>
      <span>{step}</span>
    </div>
  )
}
