import { ComponentProps, Fragment, ReactNode } from 'react'
import { Tab as HeadlessUITab } from '@headlessui/react'

const TabGroup = (props: {
  children: ReactNode
  onChange?: (index: number) => void
  selectedIndex?: number
}) => {
  return <HeadlessUITab.Group as={Fragment} {...props} />
}

export default TabGroup

export const TabList = ({ children }: { children: ReactNode }) => {
  return (
    <HeadlessUITab.List className="grid grid-cols-2">
      {children}
    </HeadlessUITab.List>
  )
}

export const Tab = ({ children }: { children: ReactNode }) => {
  return (
    <HeadlessUITab className="border-b border-grey-3 transition-colors focus-visible:outline-none ui-selected:text-orange-2">
      {children}
    </HeadlessUITab>
  )
}

export const TabPanels = (
  props: ComponentProps<typeof HeadlessUITab.Panels>
) => {
  return <HeadlessUITab.Panels {...props} />
}

export const TabPanel = ({ children }: { children: ReactNode }) => {
  return <HeadlessUITab.Panel>{children}</HeadlessUITab.Panel>
}
