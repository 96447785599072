import { MealSummary } from '@tovala/browser-apis-combinedapi'
import {
  MenuComponentsStandardized,
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedMealCarousel,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedTwoMealPicker,
  MenuComponentStandardizedTwoMealPickerMeal,
} from '@tovala/browser-apis-menu-components'
import {
  BackgroundImageHeader,
  Meal,
  MealCarousel,
  MealImage,
  MealImageSoldOut,
  MealImageTag,
  MealWithExtra,
  QuantityStepper,
  TextImageStack,
  TwoMealPicker,
} from '@tovala/component-library'
import { AnalyticsEvent, events } from 'analytics/events'
import { clsx } from 'clsx'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'
import { useState } from 'react'
import { track } from 'utils/analytics'
import { getMealImageURL, getMealSurcharge } from 'utils/meals'
import { storageAvailable } from 'utils/storageAvailable'
import { ConfirmationDialogData } from './ConfirmationDialogs'
import MealSuggestions from './MealSuggestions'
import MenuFeedback from './MenuFeedback'
import MenuGridLayout from './MenuGridLayout'
import SpecialEventBanner from './SpecialEventBanner'

const MenuComponentsGrid = ({
  canDecrement,
  canIncrement,
  canModifySelections,
  components,
  mealIDsInFilters,
  mealSelectionIDs,
  onClickDecrement,
  onClickDeselectMealOption,
  onClickIncrement,
  onClickMeal,
  onClickSelectMealOption,
  setConfirmationDialogData,
  termID,
}: {
  canDecrement: boolean
  canIncrement: boolean
  canModifySelections: boolean
  components: MenuComponentsStandardized
  mealIDsInFilters: number[]
  mealSelectionIDs: number[]
  onClickDecrement(opts: {
    mealID: number
    mealSelectionEvent?: AnalyticsEvent | undefined
  }): void
  onClickDeselectMealOption(opts: {
    meal: MealSummary
    mealOption: MealSummary
  }): void
  onClickIncrement(opts: {
    meal: MealSummary
    mealSelectionEvent?: AnalyticsEvent | undefined
  }): void
  onClickMeal(mealID: number): void
  onClickSelectMealOption(opts: {
    meal: MealSummary
    mealOption: MealSummary
  }): void
  setConfirmationDialogData(data: ConfirmationDialogData | null): void
  termID: number
}) => {
  const bgImageHeaderVariant = useVariantByScreenSize<
    'bgImageHeaderMixed' | 'bgImageHeaderTop'
  >('bgImageHeaderMixed', {
    md: 'bgImageHeaderTop',
  })

  const [showMenuFeedback, setShowMenuFeedback] = useState(() => {
    if (storageAvailable('localStorage')) {
      let menuFeedback: number[] | string | null =
        localStorage.getItem('menuFeedback')
      menuFeedback = menuFeedback ? JSON.parse(menuFeedback) : ''

      return (
        !menuFeedback ||
        (menuFeedback &&
          typeof menuFeedback !== 'string' &&
          !menuFeedback.includes(termID))
      )
    }
  })

  function makeMealQuantityStepper({
    meal,
    onDecrement,
    onIncrement,
  }: {
    meal: { id: number; mealSummary: MealSummary }
    onDecrement?(): void
    onIncrement?(): void
  }) {
    const quantity = mealSelectionIDs.filter((x) => x === meal.id).length

    if (
      !canModifySelections ||
      (meal.mealSummary.isSoldOut && quantity === 0)
    ) {
      return { quantity: 0, stepper: null }
    }

    return {
      quantity,
      stepper: (
        <QuantityStepper
          disabledDecrement={!canDecrement}
          disabledIncrement={!canIncrement || meal.mealSummary.isSoldOut}
          labelDecrement="Remove Meal"
          labelIncrement="Add Meal"
          min={0}
          onClickDecrement={() => {
            if (onDecrement) {
              onDecrement()
            } else {
              onClickDecrement({ mealID: meal.id })
            }
          }}
          onClickIncrement={() => {
            if (onIncrement) {
              onIncrement()
            } else {
              onClickIncrement({ meal: meal.mealSummary })
            }
          }}
          quantity={quantity}
          size="medium"
        />
      ),
    }
  }

  function makeTwoMealPickerMeal(
    meal: MenuComponentStandardizedTwoMealPickerMeal
  ) {
    const { id, mealSummary } = meal

    const { stepper, quantity } = makeMealQuantityStepper({
      meal: { id, mealSummary },
    })

    return {
      ...meal,
      quantity,
      stepper,
    }
  }

  function renderMeal(component: MenuComponentStandardizedMeal) {
    const {
      id,
      image,
      imageTag,
      mealSummary,
      subtitle,
      surcharge,
      tags,
      title,
    } = component.properties

    return (
      <Meal
        image={
          <MealImage
            cover={
              mealSummary.isSoldOut ? (
                <MealImageSoldOut textSize="large" />
              ) : null
            }
            image={image}
            tag={
              imageTag ? (
                <MealImageTag
                  icon={imageTag.icon ? <img src={imageTag.icon} /> : undefined}
                >
                  {imageTag.title}
                </MealImageTag>
              ) : undefined
            }
          />
        }
        onClickMeal={() => {
          onClickMeal(id)
        }}
        stepper={makeMealQuantityStepper({ meal: { id, mealSummary } }).stepper}
        subtitle={subtitle}
        surcharge={surcharge}
        tags={tags}
        title={title}
      />
    )
  }

  function renderMealWithExtra(
    component: MenuComponentStandardizedMealWithExtra
  ) {
    const { meal, mealOption } = component.properties

    const { quantity: quantityMealOption, stepper: stepperMealOption } =
      makeMealQuantityStepper({
        meal: {
          id: mealOption.meal.id,
          mealSummary: mealOption.meal.mealSummary,
        },
        onDecrement: () => {
          onClickDecrement({
            mealID: mealOption.meal.id,
          })
        },
        onIncrement: () => {
          onClickIncrement({
            meal: mealOption.meal.mealSummary,
          })

          if (isHiddenByFilters) {
            track(events.DID_MODIFY_HIDDEN_MEAL_SELECTION, {
              meal_id: mealOption.meal.id,
              term_id: termID,
            })
          }
        },
      })

    const { stepper: stepperMeal, quantity: quantityMeal } =
      makeMealQuantityStepper({
        meal: { id: meal.id, mealSummary: meal.mealSummary },
      })

    const isHiddenByFilters =
      mealIDsInFilters.length > 0 &&
      !mealIDsInFilters.includes(mealOption.meal.id)
    const isMealOptionSelected = quantityMealOption > 0

    return (
      <MealWithExtra
        meal={
          <Meal
            image={
              <MealImage
                cover={
                  meal.mealSummary.isSoldOut ? (
                    <MealImageSoldOut textSize="large" />
                  ) : null
                }
                image={meal.image}
                tag={
                  meal.imageTag ? (
                    <MealImageTag
                      icon={
                        meal.imageTag.icon ? (
                          <img src={meal.imageTag.icon} />
                        ) : undefined
                      }
                    >
                      {meal.imageTag.title}
                    </MealImageTag>
                  ) : undefined
                }
              />
            }
            onClickMeal={() => {
              onClickMeal(meal.id)
            }}
            stepper={isMealOptionSelected ? stepperMealOption : stepperMeal}
            subtitle={meal.subtitle}
            surcharge={meal.surcharge}
            tags={meal.tags}
            title={meal.title}
          />
        }
        mealOption={{
          id: mealOption.meal.id,
          image: mealOption.meal.image,
          isDisabled: quantityMeal === 0 && !isMealOptionSelected,
          isHiddenByFilters,
          isSelected: isMealOptionSelected,
          isSoldOut:
            mealOption.meal.mealSummary.isSoldOut || meal.mealSummary.isSoldOut,
          surcharge: mealOption.meal.surcharge,
          title: mealOption.meal.mealSummary.shortSubtitle,
        }}
        onClickDisabled={() => {
          setConfirmationDialogData({ type: 'mealExtraDisabled' })
        }}
        onClickExtra={() => {
          onClickMeal(mealOption.detailsMealID)
        }}
        onSelectMealOption={() => {
          const opts = {
            meal: meal.mealSummary,
            mealOption: mealOption.meal.mealSummary,
          }
          if (isMealOptionSelected) {
            onClickDeselectMealOption(opts)
          } else {
            onClickSelectMealOption(opts)
          }
        }}
      />
    )
  }

  function renderAnimatedMealCarousel(
    component: MenuComponentStandardizedMealCarousel
  ) {
    return (
      <MealCarousel
        buttonTitle={component.properties.buttonTitle}
        mealOptions={component.properties.mealOptions.map((mealOption) => {
          return {
            ...mealOption,
            ...makeMealQuantityStepper({ meal: mealOption }),
          }
        })}
        onClickMeal={onClickMeal}
        onScrollNext={({ mealInView }) => {
          if (mealInView) {
            track(events.DID_TAP_CUSTOMIZE_IT_ARROW_RIGHT, {
              meal_id: mealInView.id,
              term_id: termID,
            })
          }
        }}
        onScrollPrev={({ mealInView }) => {
          if (mealInView) {
            track(events.DID_TAP_CUSTOMIZE_IT_ARROW_LEFT, {
              meal_id: mealInView.id,
              term_id: termID,
            })
          }
        }}
      />
    )
  }

  function renderTwoMealPicker(
    component: MenuComponentStandardizedTwoMealPicker
  ) {
    return (
      <TwoMealPicker
        mealOptions={[
          makeTwoMealPickerMeal(component.properties.meals[0]),
          makeTwoMealPickerMeal(component.properties.meals[1]),
        ]}
        onClickMeal={onClickMeal}
        onClickMealOption={() => {
          // Pass
        }}
      />
    )
  }

  return (
    <MenuGridLayout>
      {components.map((component, index) => {
        if (component.type === 'meal') {
          return (
            <div key={`meal-${component.properties.id}`} className="md:px-4">
              {renderMeal(component)}
            </div>
          )
        } else if (component.type === 'mealWithExtra') {
          return (
            <div
              key={`mealWithExtra-${component.properties.meal.id}`}
              className="md:px-4"
            >
              {renderMealWithExtra(component)}
            </div>
          )
        } else if (component.type === 'twoMealPicker') {
          return (
            <div
              key={`twoMealPicker-${component.properties.meals[0].id}`}
              className="md:px-4"
            >
              {renderTwoMealPicker(component)}
            </div>
          )
        } else if (component.type === 'animatedMealCarousel') {
          return (
            <div key={`mealCarousel-${component.properties.mealOptions[0].id}`}>
              {renderAnimatedMealCarousel(component)}
            </div>
          )
        } else if (component.type === 'suggestions') {
          return (
            <div
              key={`suggestions-${index}`}
              className="col-span-2 md:col-span-1 md:px-4"
            >
              <MealSuggestions
                mealSuggestions={component.properties.suggestions.map(
                  (meal) => {
                    return {
                      id: meal.id,
                      image: { url: getMealImageURL(meal) },
                      isSoldOut: meal.isSoldOut,
                      stepper: makeMealQuantityStepper({
                        meal: { id: meal.id, mealSummary: meal },
                        onDecrement: () => {
                          onClickDecrement({
                            mealID: meal.id,
                            mealSelectionEvent:
                              events.MODIFIED_MEAL_SELECTION_MEALS_YOU_LOVED,
                          })
                        },
                        onIncrement: () => {
                          onClickIncrement({
                            meal,
                            mealSelectionEvent:
                              events.MODIFIED_MEAL_SELECTION_MEALS_YOU_LOVED,
                          })
                        },
                      }).stepper,
                      surcharge: getMealSurcharge({ meal }),
                      title: meal.title,
                    }
                  }
                )}
                onClickMeal={onClickMeal}
              />
            </div>
          )
        } else if (component.type === 'textBanner') {
          return (
            <div
              key={`textBanner-${index}`}
              className="col-span-2 md:col-span-1 md:px-4"
            >
              <SpecialEventBanner>
                {component.properties.title}
              </SpecialEventBanner>
            </div>
          )
        } else if (showMenuFeedback && component.type === 'menuFeedback') {
          return (
            <div
              key={`menuFeedback-${index}`}
              className="col-span-2 md:col-span-1 md:px-4"
            >
              <MenuFeedback
                onMenuFeedbackSaved={() => {
                  setShowMenuFeedback(false)
                }}
                options={component.properties.options}
                subtitle={component.properties.subtitle}
                tableID={component.properties.tableID}
                termID={termID}
                title={component.properties.title}
              />
            </div>
          )
        } else if (component.type === 'backgroundImageHeader') {
          return (
            <div
              key={`backgroundImageHeader-${index}`}
              className={clsx('min-h-96 md:aspect-4/3 sm:aspect-square', {
                '-mt-6':
                  index === 0 && bgImageHeaderVariant === 'bgImageHeaderTop',
              })}
            >
              <BackgroundImageHeader
                image={component.properties.image}
                subtitle={component.properties.subtitle}
                subtitleColor={component.properties.subtitleColor}
                title={component.properties.title}
                titleColor={component.properties.titleColor}
              />
            </div>
          )
        } else if (component.type === 'textImageStack') {
          return (
            <div key={index} className="col-span-2 md:col-span-1">
              <TextImageStack
                image={component.properties.image}
                subtitle={component.properties.subtitle}
                title={component.properties.title}
              >
                <MenuGridLayout>
                  {component.properties.children.map((child) => {
                    if (child.type === 'meal') {
                      return (
                        <div
                          key={`meal-${child.properties.id}`}
                          className="md:px-4"
                        >
                          {renderMeal(child)}
                        </div>
                      )
                    } else if (child.type === 'mealWithExtra') {
                      return (
                        <div
                          key={`mealWithExtra-${child.properties.meal.id}`}
                          className="md:px-4"
                        >
                          {renderMealWithExtra(child)}
                        </div>
                      )
                    } else if (child.type === 'twoMealPicker') {
                      return (
                        <div
                          key={`twoMealPicker-${child.properties.meals[0].id}`}
                          className="md:px-4"
                        >
                          {renderTwoMealPicker(child)}
                        </div>
                      )
                    } else if (child.type === 'animatedMealCarousel') {
                      return (
                        <div
                          key={`mealCarousel-${child.properties.mealOptions[0].id}`}
                        >
                          {renderAnimatedMealCarousel(child)}
                        </div>
                      )
                    }
                  })}
                </MenuGridLayout>
              </TextImageStack>
            </div>
          )
        }
      })}
    </MenuGridLayout>
  )
}

export default MenuComponentsGrid
