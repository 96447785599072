import { useMenuComponents } from '@tovala/browser-apis-cdn'
import {
  MenuListingsLayout,
  PublicTermStatus,
} from '@tovala/browser-apis-combinedapi'
import { getMenuMealComponents } from '@tovala/browser-apis-menu-components'
import { ArrowLeftIcon, Button } from '@tovala/component-library'
import { events, sourceIDs } from 'analytics/events'
import { clsx } from 'clsx'
import Link from 'components/common/Link'
import { useUser } from 'contexts/user'
import { useMealSummaries } from 'hooks/combinedAPI/meals'
import { useDetailsDialog } from 'hooks/mealDetails'
import { useMealsSignUp } from 'hooks/mealsSignUp'
import { flatMap } from 'lodash-es'
import { useNavigate } from 'react-router-dom'
import { track } from 'utils/analytics'
import { DATE_FORMATS, formatDate } from 'utils/dates'
import { getTotalCreditsCents } from 'utils/user'
import CreditsBanner from './CreditsBanner'
import ListingDetailsDialog from './ExtraDetailsDialog'
import ExtrasGrid from './ExtrasGrid'
import MealDetailsDialog from './MealDetailsDialog'
import MenuComponentsGrid from './MenuComponentsGrid'
import MenuFixedBottomContainer from './MenuFixedBottomContainer'
import MenuTabGroup, {
  ExtrasTabPanel,
  MealsTabPanel,
  useMenuTabs,
} from './MenuTabGroup'
import MenuUnavailable from './MenuUnavailable'
import StatusDot from './StatusDot'

const PublicMenu = ({
  menuListingsLayout,
  selectedPublicTerm,
}: {
  menuListingsLayout: MenuListingsLayout
  selectedPublicTerm: PublicTermStatus
}) => {
  const { user } = useUser()
  const isPaused = user.subscription.status === 'paused'

  const menuTabs = useMenuTabs()

  const { closeDetailsDialog, detailsType, listing, meal, openDetailsDialog } =
    useDetailsDialog({
      listings: flatMap(menuListingsLayout.sections, (section) => {
        return section ? section.listings : []
      }),
      termID: selectedPublicTerm.termID,
    })

  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID: selectedPublicTerm.selectedSubTermID,
  })

  const { data: menuComponents } = useMenuComponents({
    subTermID: selectedPublicTerm.selectedSubTermID,
  })
  const { components = [] } = menuComponents || {}

  const resolvedMenuComponents = getMenuMealComponents({
    mealSwaps: selectedPublicTerm.subTerms[0].mainMenu.mealSwaps,
    meals: mealSummaries,
    menuComponents: components,
    specialEvent: selectedPublicTerm.specialEvent,
    suggestions: [],
  })

  const { link } = useMealsSignUp({ utmSource: 'menu' })

  const commonMenuComponentGridProps = {
    canDecrement: false,
    canIncrement: false,
    canModifySelections: false,
    mealIDsInFilters: [],
    mealSelectionIDs: [],
    onClickDecrement: () => {
      // Pass
    },
    onClickDeselectMealOption: () => {
      // Pass
    },
    onClickIncrement: () => {
      // Pass
    },
    onClickMeal: (mealID: number) => {
      openDetailsDialog({ type: 'meal', id: mealID })
    },
    onClickSelectMealOption: () => {
      // Pass
    },
    setConfirmationDialogData: () => {
      // Pass
    },
    termID: selectedPublicTerm.termID,
  }

  const menuHasListings =
    menuListingsLayout.sections &&
    menuListingsLayout.sections.some((section) => section?.listings.length > 0)

  return (
    <>
      {selectedPublicTerm && (
        <>
          {selectedPublicTerm.readyForView ? (
            <MenuTabGroup
              menuHeader={({ isHeaderSticky }) => {
                return (
                  <PublicMenuHeader
                    isHeaderSticky={isHeaderSticky}
                    startDate={selectedPublicTerm.startDate}
                  />
                )
              }}
              menuTabs={menuTabs}
              tabChangeEventProps={{
                maxSelections: -1,
                selectionsCount: -1,
                termID: selectedPublicTerm.termID,
              }}
              totalListingSelections={0}
            >
              <MealsTabPanel>
                <div className="mx-auto max-w-menu pb-52 pt-20 lg:px-4 md:px-0 md:pb-40">
                  <MenuComponentsGrid
                    {...commonMenuComponentGridProps}
                    components={resolvedMenuComponents}
                  />
                </div>
              </MealsTabPanel>

              <ExtrasTabPanel>
                <div className="mx-auto max-w-menu pb-52 pt-20 lg:px-4 md:px-0 md:pb-40">
                  {menuHasListings ? (
                    <ExtrasGrid
                      canModifySelections={false}
                      disabledDecrement={true}
                      disabledIncrement={true}
                      listingsSelections={[]}
                      menuListingsLayout={menuListingsLayout}
                      onClickDecrement={() => {
                        // Pass
                      }}
                      onClickIncrement={() => {
                        // Pass
                      }}
                      onClickListing={(listingID: string) => {
                        openDetailsDialog({
                          id: listingID,
                          type: 'listing',
                        })

                        track(events.OPENS_EXTRA_DETAIL, {
                          listing_id: listingID,
                        })
                      }}
                    />
                  ) : (
                    <div className="md:px-4">
                      <div className="mx-auto mt-16 max-w-[512px] rounded-lg bg-white px-10 py-4 md:px-4">
                        <div className="space-y-4 text-center md:text-left">
                          <h2 className="text-k/20_110">No Extras This Week</h2>
                          <p className="text-k/16_125 text-grey-9 md:text-body-sm">
                            Although you can usually find extras here, there are
                            none this week. Please check on another menu to find
                            extras.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </ExtrasTabPanel>
            </MenuTabGroup>
          ) : (
            <MenuUnavailable
              menuHeader={
                <PublicMenuHeader
                  isHeaderSticky={false}
                  startDate={selectedPublicTerm.startDate}
                />
              }
            />
          )}

          <MenuFixedBottomContainer
            banner={
              <CreditsBanner
                totalCreditsCents={getTotalCreditsCents({ user })}
              />
            }
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <div className="h-4 w-4 md:h-3 md:w-3">
                  <StatusDot color={isPaused ? 'grey' : 'orange'} />
                </div>

                <span className="text-k/20_110">
                  {isPaused ? 'Plan Paused' : 'No Meal Plan'}
                </span>
              </div>

              <div>
                {isPaused ? (
                  <Link
                    linkStyle="dark"
                    linkType={link.type}
                    onClick={() => {
                      track(events.ORDER_RESUME_PLAN_CTA, {
                        source_id: sourceIDs.MENU_TERM,
                      })
                    }}
                    size="large"
                    to={link.to}
                  >
                    Resume Plan
                  </Link>
                ) : (
                  <Link
                    linkStyle="dark"
                    linkType={link.type}
                    onClick={() => {
                      track(events.TAPPED_MENU_CTA_GET_STARTED)
                    }}
                    size="large"
                    to={link.to}
                  >
                    Sign Up
                  </Link>
                )}
              </div>
            </div>
          </MenuFixedBottomContainer>

          {detailsType === 'meal' && meal ? (
            <MealDetailsDialog closeModal={closeDetailsDialog} meal={meal} />
          ) : detailsType === 'listing' && listing ? (
            <ListingDetailsDialog
              listing={listing}
              onClose={closeDetailsDialog}
            />
          ) : null}
        </>
      )}
    </>
  )
}

export default PublicMenu

const PublicMenuHeader = ({
  isHeaderSticky,
  startDate,
}: {
  isHeaderSticky: boolean
  startDate: string
}) => {
  const navigate = useNavigate()

  return (
    <div className="flex items-center lg:px-4">
      <div className="absolute">
        <Button
          aria-label="My Orders"
          buttonStyle="link"
          onClick={() => {
            navigate('/my-orders')

            track(events.MENU_BACK_CTA)
          }}
          size="auto"
        >
          <div className="mr-2 h-6 w-6">
            <ArrowLeftIcon />
          </div>
          <span className="text-k/16_125 md:hidden">My Orders</span>
        </Button>
      </div>

      <h1
        className={clsx('w-full text-center md:text-k/28_130', {
          'text-k/36_110': isHeaderSticky,
          'text-k/52_110': !isHeaderSticky,
        })}
      >
        {formatDate(startDate, {
          format: DATE_FORMATS.MONTH_ABBR_DAY,
        })}
      </h1>
    </div>
  )
}
