import { ReactNode } from 'react'

const MenuFixedBottomContainer = ({
  banner,
  children,
}: {
  banner?: ReactNode
  children: ReactNode
}) => {
  return (
    <div className="fixed bottom-0 left-0 z-10 w-full bg-grey-0">
      {banner && <div>{banner}</div>}
      <div className="shadow-[0px_-1px_0px_0px_#D5D5D0]">
        <div className="mx-auto max-w-menu py-8 lg:px-4 md:py-3">
          {children}
        </div>
      </div>
    </div>
  )
}

export default MenuFixedBottomContainer
