import { MealSummary } from '@tovala/browser-apis-combinedapi'
import { UserTerm } from 'types/internal'
import { getTagBST, hasViewedOilWarning, getTagOilWarning } from 'utils/meals'
import MealExtraDisabledConfirmationDialog from './MealExtraDisabledConfirmationDialog'
import MealSelectionsConfirmationDialog, {
  AddOnPrompt,
  WantMoreMealsPrompt,
} from './MealSelectionsConfirmationDialog'
import {
  RequiresCookingOilConfirmation,
  ReleaseFromCartConfirmation,
} from './Menu'
import MenuFiltersConfirmationDialog from './MenuFiltersConfirmationDialog'
import NoListingFiltersConfirmationDialog from './NoListingFiltersConfirmationDialog'
import SheetTrayConfirmation from './SheetTrayConfirmation'
import SurchargeConfirmation from './SurchargeConfirmation'
import { useAddOns } from '@tovala/browser-apis-cdn'
import { useUser } from 'contexts/user'
import { storageAvailable } from 'utils/storageAvailable'
import { isArray } from 'lodash-es'

export type ConfirmationDialogData =
  | {
      meal: MealSummary
      onConfirm(): void
      type: 'blackSheetTray' | 'oilWarning' | 'releaseFromCart' | 'surcharge'
    }
  | { type: 'filteredAllergens' }
  | { type: 'mealExtraDisabled' }
  | { onConfirm(): void; type: 'noListingFilters' }
  | { openSource?: 'orderSummary'; type: 'mealsSelected' }

const ConfirmationDialogs = ({
  confirmationDialogData,
  hasBlackSheetTray,
  onChangeConfirmationDialog,
  onClickViewSummary,
  onClickWantMoreMeals,
  onClose,
  onDecrementMeal,
  selectedUserTerm,
  totalOrderCount,
}: {
  confirmationDialogData: ConfirmationDialogData
  hasBlackSheetTray: boolean | null
  onChangeConfirmationDialog(data: ConfirmationDialogData): void
  onClickViewSummary(): void
  onClickWantMoreMeals(): void
  onClose(): void
  onDecrementMeal(opts: { mealID: number }): void
  selectedUserTerm: UserTerm
  totalOrderCount: number | undefined
}) => {
  const addOn = useApplicableAddOn({
    termID: selectedUserTerm.termID,
    totalOrderCount: totalOrderCount ?? 0,
  })

  if (confirmationDialogData.type === 'mealsSelected') {
    return (
      <MealSelectionsConfirmationDialog
        nextActionPrompt={
          addOn ? (
            <AddOnPrompt addOn={addOn} />
          ) : // If a user is at the largest subscription type, they can't add more meals so we
          // shouldn't show a prompt for them to add more.
          selectedUserTerm.nextLargestSubscriptionType ? (
            <WantMoreMealsPrompt
              onClickAddMoreMeals={() => {
                onClickWantMoreMeals()
                onClose()
              }}
            />
          ) : null
        }
        onClickViewSummary={onClickViewSummary}
        onCloseConfirmation={() => {
          onClose()
        }}
        openSource={confirmationDialogData.openSource}
        selectedUserTerm={selectedUserTerm}
      />
    )
  }

  if (confirmationDialogData.type === 'filteredAllergens') {
    return (
      <MenuFiltersConfirmationDialog
        onClose={() => {
          onClose()
        }}
      />
    )
  }

  if (confirmationDialogData.type === 'mealExtraDisabled') {
    return (
      <MealExtraDisabledConfirmationDialog
        onClose={() => {
          onClose()
        }}
      />
    )
  }

  if (confirmationDialogData.type === 'noListingFilters') {
    return (
      <NoListingFiltersConfirmationDialog
        onClose={() => {
          confirmationDialogData.onConfirm()
        }}
      />
    )
  }

  const { onConfirm, meal, type } = confirmationDialogData
  const bstTag = getTagBST({ meal })
  const oilWarning = hasViewedOilWarning(meal)
    ? null
    : getTagOilWarning({ meal })

  if (type === 'blackSheetTray') {
    return (
      <SheetTrayConfirmation
        oilWarning={oilWarning}
        onClose={onClose}
        onConfirm={() => {
          onConfirm()

          if (oilWarning) {
            setMealWarningAsViewed(oilWarning.id)
          }

          onClose()
        }}
      />
    )
  }

  if (type === 'oilWarning' && oilWarning) {
    return (
      <RequiresCookingOilConfirmation
        oilWarning={oilWarning}
        onClose={onClose}
        onConfirm={() => {
          onConfirm()
          setMealWarningAsViewed(oilWarning.id)
          onClose()
        }}
      />
    )
  }

  if (type === 'releaseFromCart') {
    return (
      <ReleaseFromCartConfirmation
        onClickClose={() => {
          onClose()
        }}
        onClickConfirm={() => {
          onDecrementMeal({ mealID: meal.id })
          onClose()
        }}
      />
    )
  }

  if (type === 'surcharge') {
    const confirmBST = !!bstTag && !hasBlackSheetTray

    return (
      <SurchargeConfirmation
        onClose={onClose}
        onConfirm={() => {
          if (confirmBST) {
            onChangeConfirmationDialog({
              meal,
              onConfirm,
              type: 'blackSheetTray',
            })
          } else {
            onConfirm()
          }

          onClose()
        }}
      />
    )
  }

  return null
}

export default ConfirmationDialogs

function setMealWarningAsViewed(tagID: number) {
  if (storageAvailable('localStorage')) {
    let mealWarnings = localStorage.getItem('mealWarnings')
    mealWarnings = mealWarnings ? JSON.parse(mealWarnings) : []

    if (isArray(mealWarnings)) {
      mealWarnings.push(tagID)
      localStorage.setItem('mealWarnings', JSON.stringify(mealWarnings))
    }
  }
}

function useApplicableAddOn({
  termID,
  totalOrderCount,
}: {
  termID: number
  totalOrderCount: number
}) {
  const { user } = useUser()

  const { data: getAddOnsResponse } = useAddOns()
  const addOns = getAddOnsResponse?.addOns ?? []
  return totalOrderCount > 5
    ? addOns.find((addOn) => {
        return (
          addOn.termID === termID &&
          addOn.states.includes(user.shippingAddresses[0]?.state)
        )
      })
    : undefined
}
