import { Button, ButtonLoading } from '@tovala/component-library'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import {
  TermStatusSubTerm,
  UserSubscriptionType,
  UserV1,
  getUserTermStatuses,
  useInvalidateTermStatuses,
  useSkipWeek,
  useUserSubscriptionTypes,
} from '@tovala/browser-apis-combinedapi'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { DATE_FORMATS, formatDate } from '../../utils/dates'
import { ErrorCodeMessageMapCombinedAPI, UserTerm } from 'types/internal'
import { events, getSourceIDFromPathname } from '../../analytics/events'
import { formatCentsToDollars } from 'utils/currency'
import { getMealSelectionIDs } from 'utils/meals'
import { getOrderCutoffFormattedDate } from 'utils/terms'
import { track } from 'utils/analytics'

import { useChangeSubscriptionStatus } from 'hooks/combinedAPI/subscriptions'
import { useHasPastOrders } from 'hooks/orderHistory'
import { useOverlays } from 'contexts/overlays'
import { useToast } from 'contexts/toast'
import { useTypeformPopup } from 'hooks/typeform'
import { useUnavailableMealSelectionsForDeliveryDayChange } from 'hooks/combinedAPI/meals'
import { useUpsertUserTermStatus } from 'hooks/combinedAPI/termStatus'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from 'components/common/ConfirmationDialog'
import DeliveryDays from './DeliveryDays'
import MealPlans from './MealPlans'
import Sidebar, {
  SidebarBody,
  SidebarButtons,
  SidebarHeader,
} from 'components/common/Sidebar'
import TermSkippedSidebarBody from './TermSkippedSidebarBody'
import TermSoldOutSidebarBody from './TermSoldOutSIdebarBody'
import { useQueryClient } from '@tanstack/react-query'

interface FormData {
  subscriptionTypeID: string
  subTermID: string
  updateSubscription: boolean
}

const EDIT_TERM_STATUS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't update your meal plan due to a technical issue on our end.",
  },
  UserSkippedTerm: {
    helpToFix: 'Please refresh the page and try again.',
    why: "We couldn't update this delivery because it has been skipped.",
  },
}

const LOAD_MEAL_PLANS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please close and reopen this sidebar to try again.',
    why: "We couldn't load our available meal plans due to a technical issue on our end.",
  },
}

const SKIP_WEEK_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't skip this week due to a technical issue on our end.",
  },
}

const EditTermStatusSidebar = ({
  onClose,
  selectedUserTerm,
}: {
  onClose(): void
  selectedUserTerm: UserTerm
}) => {
  if (selectedUserTerm.isStaticSkipped) {
    return (
      <SidebarContainer
        onCloseSidebar={onClose}
        selectedUserTerm={selectedUserTerm}
      >
        <TermSoldOutSidebarBody onClickMyOrders={onClose} />
      </SidebarContainer>
    )
  } else if (selectedUserTerm.isSkipped) {
    return (
      <SidebarContainer
        onCloseSidebar={onClose}
        selectedUserTerm={selectedUserTerm}
      >
        <TermSkippedSidebarBody selectedUserTerm={selectedUserTerm} />
      </SidebarContainer>
    )
  }

  return (
    <EditTermStatus
      onClose={() => {
        onClose()
      }}
      selectedUserTerm={selectedUserTerm}
    />
  )
}

export default EditTermStatusSidebar

const SidebarContainer = ({
  buttons,
  children,
  onCloseSidebar,
  selectedUserTerm,
}: {
  buttons?: ReactNode
  children: ReactNode
  onCloseSidebar(): void
  selectedUserTerm: UserTerm
}) => {
  return (
    <Sidebar onCloseSidebar={onCloseSidebar}>
      <SidebarHeader
        onClickClose={() => {
          track(events.ADJUST_POP_UP_DISMISS)
          onCloseSidebar()
        }}
      >
        Week of{' '}
        {formatDate(selectedUserTerm.startDate, {
          format: DATE_FORMATS.MONTH_DAY,
        })}
      </SidebarHeader>

      <SidebarBody>
        <div className="px-6 py-10 md:px-4">{children}</div>
      </SidebarBody>

      {buttons}
    </Sidebar>
  )
}

const EditTermStatus = ({
  onClose,
  selectedUserTerm,
}: {
  onClose(): void
  selectedUserTerm: UserTerm
}) => {
  const { pathname } = useLocation()

  const { openToast } = useToast()

  const { user } = useUser()

  const queryClient = useQueryClient()

  const mealSelectionIDs = getMealSelectionIDs({ selectedUserTerm })

  const { unavailableMealSelections } =
    useUnavailableMealSelectionsForDeliveryDayChange({ term: selectedUserTerm })

  const [showDiscardChangesConfirmation, setShowDiscardChangesConfirmation] =
    useState(false)
  const [showTooManyMealsConfirmation, setShowTooManyMealsConfirmation] =
    useState(false)
  const [
    showMealsUnavailableConfirmation,
    setShowMealsUnavailableConfirmation,
  ] = useState(false)

  const [
    showUpdateSubscriptionConfirmation,
    setShowUpdateSubscriptionConfirmation,
  ] = useState(false)

  const {
    data: getUserSubscriptionTypesResponse,
    error: loadUserSubscriptionTypesError,
    isError: hasLoadUserSubscriptionTypesError,
  } = useUserSubscriptionTypes({
    userID: user.id,
  })
  const userSubscriptionTypes =
    getUserSubscriptionTypesResponse?.subscription_types ?? []

  const {
    error: changeSubscriptionStatusError,
    isError: hasChangeSubscriptionStatusError,
    isLoading: isChangingSubscriptionStatus,
    mutate: changeSubscriptionStatus,
  } = useChangeSubscriptionStatus({
    onSuccess: () => {
      openToast({
        heading: 'Meal Plan Updated',
        message: 'Please double check any pending orders.',
        type: 'success',
      })

      onClose()
    },
  })

  const {
    error: upsertUserTermStatusError,
    isError: hasUpsertUserTermStatusError,
    isLoading: isUpsertingUserTermStatus,
    mutate: upsertUserTermStatus,
  } = useUpsertUserTermStatus({
    onSuccess: (_data, { data, updateSubscription }) => {
      if (updateSubscription) {
        handleUpdateSubscription({
          subscriptionTypeID: data.subscriptionTypeID,
          subTermID: data.subTermID,
        })
      } else {
        const selectedSubTerm = selectedUserTerm.subTerms.find(
          (subTerm) => subTerm.subTermID === data.subTermID
        )
        const deliveryDate = selectedSubTerm?.deliveryDate

        if (
          deliveryDate &&
          deliveryDate !== selectedUserTerm.selectedSubTerm?.deliveryDate
        ) {
          track(events.USER_EDITS_DELIVERY, {
            delivery_day_of_week: deliveryDate,
          })
        }

        openToast({
          heading: 'Updates Saved',
          message: `Updates applied to your ${
            deliveryDate
              ? formatDate(deliveryDate, {
                  format: DATE_FORMATS.DOW_MONTH_DAY,
                })
              : ''
          } delivery.`,
          type: 'success',
        })

        onClose()
      }
    },
  })

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      subscriptionTypeID: selectedUserTerm.subscriptionType?.id ?? '',
      subTermID: selectedUserTerm.selectedSubTermID,
      updateSubscription: false,
    },
  })

  const selectedSubscriptionTypeID = watch('subscriptionTypeID')
  const updateSubscription = watch('updateSubscription')

  const deliveryOptions = selectedUserTerm.subTerms.map((subTerm) => ({
    label: formatDate(subTerm.deliveryDate, {
      format: DATE_FORMATS.DOW_FULL_MONTH_DAY,
    }),
    value: subTerm.subTermID,
  }))

  const selectedSubscription = userSubscriptionTypes.find((type) => {
    return type.id === selectedSubscriptionTypeID
  })

  const handleUpdateSubscription = (
    values: Pick<FormData, 'subscriptionTypeID' | 'subTermID'>
  ) => {
    const selectedSubscription = userSubscriptionTypes.find(
      (type) => type.id === values.subscriptionTypeID
    )
    const selectedSubTerm = selectedUserTerm.subTerms.find(
      (subTerm) => subTerm.subTermID === values.subTermID
    )

    if (!selectedSubTerm) {
      return
    }

    track(events.TAPS_APPLY_TO_ALL_ORDERS, {
      original_order_size: selectedUserTerm.subscriptionType?.maxSelections,
      new_order_size: selectedSubscription
        ? selectedSubscription.max_selections
        : undefined,
      term_id: selectedUserTerm.termID,
    })

    const selectedShipPeriod = selectedSubTerm.shipPeriod
    const subscription = {
      defaultShipPeriod: selectedShipPeriod,
      subscriptionTypeID: values.subscriptionTypeID,
    }

    changeSubscriptionStatus(
      {
        data: subscription,
        subscriptionStatus: 'activate',
        userID: user.id,
      },
      {
        onSuccess: async () => {
          try {
            // Update all orders to match new settings, which would not have been updated
            // by changing the default settings if an UTO override had been added
            const termStatuses = await queryClient.fetchQuery({
              queryFn: () => {
                return getUserTermStatuses({ userID: user.id })
              },
              queryKey: ['term-statuses', user.id],
            })

            termStatuses.forEach((termStatus) => {
              const termStatusShipPeriod = termStatus.subTerms.find(
                (subTerm) => subTerm.subTermID === termStatus.selectedSubTermID
              )?.shipPeriod

              const subscriptionChanged =
                termStatus.subscriptionType &&
                termStatus.subscriptionType.id !== values.subscriptionTypeID
              const shipPeriodChanged =
                termStatusShipPeriod &&
                termStatusShipPeriod !== selectedShipPeriod

              if (subscriptionChanged || shipPeriodChanged) {
                let subTermID = termStatus.selectedSubTermID
                if (shipPeriodChanged) {
                  subTermID =
                    termStatus.subTerms.find(
                      (subTerm) =>
                        subTerm.subTermID !== termStatus.selectedSubTermID
                    )?.subTermID || termStatus.selectedSubTermID
                }

                upsertUserTermStatus({
                  data: {
                    notes: '',
                    subscriptionTypeID: values.subscriptionTypeID,
                    subTermID,
                    termID: termStatus.termID,
                    userID: user.id,
                  },
                  selectedUserTermID: termStatus.id,
                  userID: user.id,
                })
              }
            })
          } catch (error) {
            // Fail silently, only default and unmodified orders will be updated
          }
        },
      }
    )
  }

  const handleUpdateTermStatus = (values: FormData) => {
    if (!selectedUserTerm.subscriptionType) {
      return
    }

    upsertUserTermStatus({
      data: {
        notes: '',
        subscriptionTypeID: values.subscriptionTypeID,
        subTermID: values.subTermID,
        termID: selectedUserTerm.termID,
        userID: user.id,
      },
      previousMaxSelections: selectedUserTerm.subscriptionType.maxSelections,
      selectedUserTermID: selectedUserTerm.id,
      updateSubscription: values.updateSubscription,
      userID: user.id,
    })
  }

  const onSubmit = (values: FormData) => {
    const selectedSubscription = userSubscriptionTypes.find(
      (type) => type.id === values.subscriptionTypeID
    )

    // If changing delivery day, check if all meal selections are available for new ship period
    if (
      !showMealsUnavailableConfirmation &&
      selectedUserTerm.selectedSubTermID !== values.subTermID
    ) {
      if (unavailableMealSelections.length) {
        setShowMealsUnavailableConfirmation(true)
      } else {
        handleUpdateTermStatus(values)
      }

      // Check if there are too many selections for the selected subscription
    } else if (
      !showTooManyMealsConfirmation &&
      selectedSubscription &&
      mealSelectionIDs.length > selectedSubscription.max_selections
    ) {
      setShowTooManyMealsConfirmation(true)
    } else {
      handleUpdateTermStatus(values)

      if (showMealsUnavailableConfirmation) {
        setShowMealsUnavailableConfirmation(false)
      }

      if (showTooManyMealsConfirmation) {
        setShowTooManyMealsConfirmation(false)
      }
    }
  }

  const handleOnClickClose = () => {
    if (isDirty) {
      setShowDiscardChangesConfirmation(true)
    } else {
      onClose()
    }
  }

  return (
    <SidebarContainer
      buttons={
        <SidebarButtons>
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4 bg-grey-0 p-6 md:p-4">
              {hasChangeSubscriptionStatusError ? (
                <div className="col-span-2">
                  <APIErrorDisplay
                    error={changeSubscriptionStatusError}
                    errorCodeMessageMap={EDIT_TERM_STATUS_ERRORS}
                  />
                </div>
              ) : hasUpsertUserTermStatusError ? (
                <div className="col-span-2">
                  <APIErrorDisplay
                    error={upsertUserTermStatusError}
                    errorCodeMessageMap={EDIT_TERM_STATUS_ERRORS}
                  />
                </div>
              ) : null}

              <ButtonLoading
                buttonStyle="stroke-filled"
                disabled={!isDirty}
                isLoading={
                  updateSubscription &&
                  (isUpsertingUserTermStatus || isChangingSubscriptionStatus)
                }
                onClick={() => {
                  track(events.ADJUST_UPDATE_ORDER_ALL)

                  setValue('updateSubscription', true)
                  handleSubmit(onSubmit)()
                }}
                size="large"
                type="submit"
              >
                Update All Orders
              </ButtonLoading>
              <ButtonLoading
                buttonStyle="dark"
                disabled={!isDirty}
                isLoading={isUpsertingUserTermStatus && !updateSubscription}
                onClick={() => {
                  track(events.ADJUST_UPDATE_ORDER)

                  handleSubmit(onSubmit)()
                }}
                size="large"
                type="submit"
              >
                Update This Order
              </ButtonLoading>
            </div>
          </div>
        </SidebarButtons>
      }
      onCloseSidebar={handleOnClickClose}
      selectedUserTerm={selectedUserTerm}
    >
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-k/20_125" id="delivery-day">
          Delivery Day
        </h3>

        <SkipDelivery
          onChangeField={(
            fieldName: 'subscriptionTypeID' | 'subTermID',
            value: string
          ) => {
            setValue(fieldName, value)
          }}
          onClose={onClose}
          selectedUserTerm={selectedUserTerm}
          user={user}
          userSubscriptionTypes={userSubscriptionTypes}
        />
      </div>

      <form>
        {deliveryOptions && (
          <div className="mb-10">
            <DeliveryDays
              control={control}
              deliveryOptions={deliveryOptions}
              name="subTermID"
            />
          </div>
        )}

        <h3 className="mb-4 text-k/20_125" id="meals">
          Meals per Order
        </h3>

        {userSubscriptionTypes.length > 0 ? (
          <div
            aria-labelledby="meals"
            className="mb-10 grid auto-rows-[88px] grid-cols-[repeat(auto-fill,78px)] gap-4"
            role="radiogroup"
          >
            <MealPlans<FormData>
              control={control}
              mealPlans={userSubscriptionTypes}
              name="subscriptionTypeID"
              onChangePlan={(mealPlan) => {
                track(events.USER_EDITS_PLAN, {
                  plan_size: mealPlan.max_selections,
                  source_id: getSourceIDFromPathname(pathname),
                })
              }}
            />
          </div>
        ) : hasLoadUserSubscriptionTypesError ? (
          <APIErrorDisplay
            error={loadUserSubscriptionTypesError}
            errorCodeMessageMap={LOAD_MEAL_PLANS_ERRORS}
          />
        ) : null}

        {selectedSubscription && (
          <div className="divide-y">
            <h3 className="mb-4 text-k/20_125">Order Summary</h3>

            <div className="flex justify-between py-4 text-k/13_120 text-grey-9">
              <span>Meal Cost</span>
              <span>
                {formatCentsToDollars(selectedSubscription.price_cents)}
              </span>
            </div>

            <div className="flex justify-between py-4 text-k/13_120 text-grey-9">
              <span>Shipping</span>
              <span>
                {selectedSubscription.shippingCents > 0
                  ? `${formatCentsToDollars(
                      selectedSubscription.shippingCents
                    )}`
                  : 'Free'}
              </span>
            </div>

            <div className="flex justify-between pt-4 text-k/16_125">
              <span>Estimated Total</span>
              <span>
                {formatCentsToDollars(
                  selectedSubscription.price_cents +
                    selectedSubscription.shippingCents
                )}
              </span>
            </div>
          </div>
        )}

        <p className="flex justify-between pt-6 text-center text-grey-9">
          Select meals or skip this delivery by{' '}
          {getOrderCutoffFormattedDate({
            orderByDate: selectedUserTerm.orderByDate,
          })}
          .
        </p>
      </form>

      {showTooManyMealsConfirmation && (
        <TooManyMealsConfirmation
          maxSelections={selectedSubscription?.max_selections}
          onClickClose={() => {
            setShowTooManyMealsConfirmation(false)
            onClose()
          }}
          onClickConfirm={handleSubmit(onSubmit)}
          selectionsCount={mealSelectionIDs.length}
        />
      )}

      {showMealsUnavailableConfirmation && (
        <MealsUnavailableConfirmation
          onClickClose={() => {
            setShowMealsUnavailableConfirmation(false)
            onClose()
          }}
          onClickConfirm={handleSubmit(onSubmit)}
          unavailableMealSelections={unavailableMealSelections}
        />
      )}

      {showUpdateSubscriptionConfirmation && (
        <UpdateSubscriptionConfirmation
          onClickClose={() => setShowUpdateSubscriptionConfirmation(false)}
          onClickConfirm={handleSubmit(onSubmit)}
        />
      )}

      {showDiscardChangesConfirmation && (
        <DiscardChangesConfirmation
          onClickClose={() => setShowDiscardChangesConfirmation(false)}
          onClickConfirm={onClose}
        />
      )}
    </SidebarContainer>
  )
}

const SkipDelivery = ({
  onChangeField,
  onClose,
  selectedUserTerm,
  user,
  userSubscriptionTypes,
}: {
  onChangeField(
    fieldName: 'subscriptionTypeID' | 'subTermID',
    value: string
  ): void
  onClose(): void
  selectedUserTerm: UserTerm
  user: UserV1
  userSubscriptionTypes: UserSubscriptionType[]
}) => {
  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()

  const { hasViewedSkipSurvey, setHasViewedSkipSurvey } = useOverlays()
  const { openToast } = useToast()

  const [openSkipWeekConfirmation, setOpenSkipWeekConfirmation] =
    useState(false)

  const hasPastOrders = useHasPastOrders({ userID: user.id })

  const {
    selectedSubTerm,
    selectedSubTermID,
    subscriptionType,
    subTerms,
    termID,
  } = selectedUserTerm

  const alternativeSubTerm = subTerms?.find(
    (subTerm) => subTerm.subTermID !== selectedSubTermID
  )
  const termSubscriptionIndex = subscriptionType
    ? userSubscriptionTypes
        .map((type) => type.max_selections)
        .indexOf(subscriptionType.maxSelections)
    : -1
  const smallerSubscription =
    termSubscriptionIndex > 0
      ? userSubscriptionTypes[termSubscriptionIndex - 1]
      : undefined

  const shouldConfirmSkipDelivery = !!(
    smallerSubscription || alternativeSubTerm
  )

  const deliveryDate = selectedSubTerm?.deliveryDate

  const openToastSkipSuccess = useCallback(() => {
    openToast({
      heading: 'Delivery Skipped',
      message: deliveryDate
        ? `You will not receive a delivery ${formatDate(deliveryDate, {
            format: DATE_FORMATS.DOW_MONTH_DAY,
          })}`
        : 'Your delivery was skipped successfully.',
      type: 'success',
    })

    onClose()
    setHasViewedSkipSurvey(true)
  }, [deliveryDate, onClose, openToast, setHasViewedSkipSurvey])

  const hiddenFields = useMemo(
    () => ({
      termid: `${termID}`,
    }),
    [termID]
  )

  const { openTypeformPopup } = useTypeformPopup({
    hiddenFields,
    onClose: openToastSkipSuccess,
    survey: 'skip',
    userID: `${user.id}`,
  })

  // In the event the user is skipping multiple weeks, we should only show the skip survey once per session
  // and only to users that have at least one meal order
  const shouldShowSkipSurvey = !hasViewedSkipSurvey && hasPastOrders

  const {
    error: skipWeekError,
    isError: hasSkipWeekError,
    isLoading: isSkippingWeek,
    mutate: skipWeek,
  } = useSkipWeek({
    onSuccess: () => {
      invalidateUserTermStatuses(user.id)

      setOpenSkipWeekConfirmation(false)

      if (shouldShowSkipSurvey) {
        openTypeformPopup()
      } else {
        openToastSkipSuccess()
      }
    },
  })

  const handleSkip = () => {
    skipWeek({
      data: {
        termid: selectedUserTerm.termID,
      },
      userID: user.id,
    })
  }

  return (
    <>
      <ButtonLoading
        buttonStyle="white"
        isLoading={isSkippingWeek}
        onClick={() => {
          track(events.SKIP_DELIVERY_CTA)

          if (shouldConfirmSkipDelivery) {
            setOpenSkipWeekConfirmation(true)
          } else {
            handleSkip()
          }
        }}
        size="small"
      >
        Skip Delivery
      </ButtonLoading>
      {openSkipWeekConfirmation && (
        <SkipWeekConfirmation
          alternativeSubTerm={alternativeSubTerm}
          onClickClose={() => {
            setOpenSkipWeekConfirmation(false)
          }}
          onClickFewerMeals={(smallerSubscription) => {
            onChangeField('subscriptionTypeID', smallerSubscription.id)
            setOpenSkipWeekConfirmation(false)
          }}
          onClickSkip={handleSkip}
          onClickSwitchDelivery={(alternativeSubterm) => {
            onChangeField('subTermID', alternativeSubterm.subTermID)
            setOpenSkipWeekConfirmation(false)
          }}
          selectedUserTerm={selectedUserTerm}
          skipWeekError={hasSkipWeekError ? skipWeekError : null}
          smallerSubscription={smallerSubscription}
        />
      )}
    </>
  )
}

export const TooManyMealsConfirmation = ({
  maxSelections,
  onClickClose,
  onClickConfirm,
  selectionsCount,
}: {
  maxSelections: number | undefined
  onClickClose(): void
  onClickConfirm(): void
  selectionsCount: number
}) => {
  const mealsDifference =
    maxSelections && maxSelections < selectionsCount
      ? selectionsCount - maxSelections
      : undefined

  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader heading="Removal of Meal Selections" />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">
            This update removes the last {mealsDifference} meal
            {mealsDifference === 1 ? '' : 's'} you selected from your delivery.
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid grid-cols-2 gap-2">
          <Button buttonStyle="stroke" onClick={onClickClose} size="large">
            Cancel
          </Button>

          <Button onClick={onClickConfirm} size="large">
            Update Delivery
          </Button>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export const MealsUnavailableConfirmation = ({
  onClickClose,
  onClickConfirm,
  unavailableMealSelections,
}: {
  onClickClose(): void
  onClickConfirm(): void
  unavailableMealSelections: { title: string }[]
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader heading="Meal Selections Unavailable" />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <div className="mx-auto max-w-sm space-y-4 text-body-lg">
            <p>
              The following meal selections are unavailable on this delivery
              day:
            </p>

            <div>
              {unavailableMealSelections.map((meal) => {
                return <p key={meal.title}>{meal.title}</p>
              })}
            </div>
          </div>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid grid-cols-2 gap-2">
          <Button buttonStyle="stroke" onClick={onClickClose} size="large">
            Cancel
          </Button>

          <Button onClick={onClickConfirm} size="large">
            Update Delivery Anyway
          </Button>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

const SkipWeekConfirmation = ({
  alternativeSubTerm,
  onClickClose,
  onClickFewerMeals,
  onClickSkip,
  onClickSwitchDelivery,
  skipWeekError,
  smallerSubscription,
}: {
  alternativeSubTerm: TermStatusSubTerm | undefined
  onClickClose(): void
  onClickFewerMeals(smallerSubscription: UserSubscriptionType): void
  onClickSkip(): void
  onClickSwitchDelivery(alternativeSubTerm: TermStatusSubTerm): void
  selectedUserTerm: UserTerm
  skipWeekError: Error | null
  smallerSubscription: UserSubscriptionType | undefined
}) => {
  let heading = 'Get Fewer Meals or Switch Your Delivery Day?'
  let message =
    'Another delivery day is available for this week. Would you like to switch delivery days or get fewer meals?'

  if (!alternativeSubTerm) {
    heading = 'Get Fewer Meals?'
    message =
      'A smaller meal plan is available for this week. Would you like to get fewer meals?'
  } else if (!smallerSubscription) {
    heading = 'Switch Your Delivery Day?'
    message =
      'Another delivery day is available for this week. Would you like to switch delivery days?'
  }

  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader heading={heading} />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">{message}</p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="space-y-4">
          {skipWeekError && (
            <APIErrorDisplay
              error={skipWeekError}
              errorCodeMessageMap={SKIP_WEEK_ERRORS}
            />
          )}

          <div className="flex flex-col items-center space-y-4">
            <Button onClick={onClickSkip} size="large">
              Skip Delivery
            </Button>

            {alternativeSubTerm && (
              <Button
                buttonStyle="stroke"
                onClick={() => onClickSwitchDelivery(alternativeSubTerm)}
                size="large"
              >
                Deliver{' '}
                {formatDate(alternativeSubTerm.deliveryDate, {
                  format: DATE_FORMATS.DOW_MONTH_DAY,
                })}{' '}
                Instead
              </Button>
            )}

            {smallerSubscription && (
              <Button
                buttonStyle="stroke"
                onClick={() => onClickFewerMeals(smallerSubscription)}
                size="large"
              >
                Get Fewer Meals
              </Button>
            )}
          </div>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export const UpdateSubscriptionConfirmation = ({
  onClickClose,
  onClickConfirm,
}: {
  onClickClose(): void
  onClickConfirm(): void
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader heading="Meal Plan Changes" />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">
            Editing your meal plan affects any deliveries that haven't been
            processed, skipped, or had their delivery size changed.
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid grid-cols-2 gap-2">
          <Button buttonStyle="stroke" onClick={onClickClose} size="large">
            Cancel
          </Button>

          <Button onClick={onClickConfirm} size="large">
            Save
          </Button>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export const DiscardChangesConfirmation = ({
  onClickClose,
  onClickConfirm,
}: {
  onClickClose(): void
  onClickConfirm(): void
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader heading="Discard Delivery Updates?" />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">
            Continuing will discard your changes. Are you sure you want to leave
            this screen?
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid grid-cols-2 gap-2">
          <Button buttonStyle="stroke" onClick={onClickClose} size="large">
            Cancel
          </Button>

          <Button onClick={onClickConfirm} size="large">
            Discard Updates
          </Button>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}
