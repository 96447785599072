import { useOvenChannels, useOvens } from '@tovala/browser-apis-combinedapi'

export const useOvenChannel = ({ userID }: { userID: number | undefined }) => {
  const { data: userOvens = [], isLoading: isLoadingOvens } = useOvens({
    userID,
  })
  const userOvenSerialNumber = userOvens[0]?.tovala.serial
  const ovenChannelsResponse = useOvenChannels({
    retry: false,
    serialNumbers: [userOvenSerialNumber],
  })
  const isLoadingOvenChannels = userOvenSerialNumber
    ? ovenChannelsResponse.some((res) => res.isLoading)
    : false

  const ovenPurchaseChannel = ovenChannelsResponse[0].data

  return {
    data: {
      ovenChannel: ovenPurchaseChannel?.channel,
      ovenSubChannel: ovenPurchaseChannel?.subChannel,
    },
    isLoading: isLoadingOvens || isLoadingOvenChannels,
  }
}
