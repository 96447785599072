import { ArrowLeftIcon, Button, FilterIcon } from '@tovala/component-library'
import { compact, flatMap, isEmpty, some, throttle } from 'lodash-es'
import {
  MealSummary,
  MealTag,
  MenuListingsLayout,
  useAddListingSelection,
  useDeleteListingSelection,
  useListingSelections,
  useMealReviewSummaries,
} from '@tovala/browser-apis-combinedapi'
import { ReactNode, useEffect, useState } from 'react'
import { useMachine } from '@xstate/react'
import { useNavigate } from 'react-router-dom'

import { AnalyticsEvent, events, sourceIDs } from '../../../analytics/events'
import { createMenuMachine } from './machines/menu'
import {
  getFilteredAllergenMealSelections,
  getFilteredComponentsCounts,
  getFilteredMenuComponents,
} from 'utils/menus'
import {
  getMealSelectionIDs,
  getMealSuggestionsForMenu,
  getTagBST,
  getTagOilWarning,
  hasViewedOilWarning,
} from 'utils/meals'
import { getMostRecentTermMealsToRate, getTermsToRate } from 'utils/terms'
import { getSubscriptionPreferences } from 'utils/subscriptions'
import { isCombinedAPIResponseError } from 'utils/api'
import { SelectedMealFilters, UserTerm } from 'types/internal'

import { getMenuMealComponents } from '@tovala/browser-apis-menu-components'
import { storageAvailable } from 'utils/storageAvailable'
import { track } from 'utils/analytics'
import { wrapWithContactSupportTeam } from 'utils/errors'

import {
  useAddMealSelection,
  useDeleteMealSelection,
  useGetMealFilters,
  useIsNoMenuTerm,
  useMealsInFilters,
  useMealSugggestions,
  useMealSummaries,
  useUpdateMealSelectionOption,
} from 'hooks/combinedAPI/meals'
import { useHasPastOrders, useOrderHistory } from 'hooks/orderHistory'
import { useDetailsDialog } from 'hooks/mealDetails'
import { useCustomer } from 'hooks/combinedAPI/customers'
import { useFeatures } from 'contexts/features'
import { useFirstOvenCookHistory } from 'hooks/combinedAPI/ovens'
import { useUpsertUserTermStatus } from 'hooks/combinedAPI/termStatus'
import { useToast } from 'contexts/toast'
import { useUpdatePlanPreferences } from 'hooks/combinedAPI/subscriptions'
import { useMenuComponents } from 'hooks/menus'
import { useUser } from 'contexts/user'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from 'components/common/ConfirmationDialog'
import ExtrasAllergenNote from './ExtrasAllergenNote'
import ExtrasGrid from './ExtrasGrid'
import ListingDetailsDialog from './ExtraDetailsDialog'
import MealDetailsDialog from './MealDetailsDialog'
import MenuFilters from './MenuFilters'
import MenuFixedBottomContainer from './MenuFixedBottomContainer'
import OrderStatus from './OrderStatus'
import OrderSummarySidebar, {
  OrderSummaryExtra,
  OrderSummaryMeal,
  useOrderSummarySidebar,
} from './OrderSummarySidebar'
import QuantityStepper from 'components/common/QuantityStepper'
import RateMealsCarousel from '../rateMeals/RateMealsCarousel'
import RateMealsPrompt from '../rateMeals/RateMealsPrompt'
import SpecialEventBanner from './SpecialEventBanner'
import MenuHeader from './MenuHeader'
import ConfirmationDialogs, {
  ConfirmationDialogData,
} from './ConfirmationDialogs'
import MenuComponentsGrid from './MenuComponentsGrid'
import MenuTabGroup, {
  ExtrasTabPanel,
  MealsTabPanel,
  useMenuTabs,
} from './MenuTabGroup'
import CreditsBanner from './CreditsBanner'
import { getTotalCreditsCents } from 'utils/user'
import MenuUnavailable from './MenuUnavailable'

const Menu = ({
  menuListingsLayout,
  onEditTermStatus,
  selectedUserTerm,
}: {
  menuListingsLayout: MenuListingsLayout
  onEditTermStatus(selectedUserTerm: UserTerm): void
  selectedUserTerm: UserTerm
}) => {
  const navigate = useNavigate()
  const { openToast } = useToast()

  const features = useFeatures()
  const { user } = useUser()

  const menuTabs = useMenuTabs()
  const selectedMenuTab = menuTabs.tabs[menuTabs.selectedIndex]

  const { components } = useMenuComponents({
    subTermID: selectedUserTerm.selectedSubTermID,
  })

  const { data: customer } = useCustomer({
    customerID: user.subscription.customerID,
    userID: user.id,
  })

  const hasPaymentSource = !!(customer && customer.sources.data.length > 0)

  const isSubscriptionActive = user.subscription.status === 'active'

  const { data: ovenCookHistory = [] } = useFirstOvenCookHistory({
    userID: user.id,
  })

  const hasPastOrders = useHasPastOrders({ userID: user.id })

  const { data: mealReviewSummaries } = useMealReviewSummaries({
    userID: user.id,
  })

  const { data: mealSuggestions } = useMealSugggestions({
    userID: user.id,
  })

  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID: selectedUserTerm.selectedSubTermID,
  })

  const { closeDetailsDialog, detailsType, listing, meal, openDetailsDialog } =
    useDetailsDialog({
      listings: flatMap(menuListingsLayout.sections, (section) => {
        return section ? section.listings : []
      }),
      termID: selectedUserTerm.termID,
    })

  const { receipts, totalOrderCount } = useOrderHistory({ userID: user.id })

  const mostRecentTermMealsToRate = getMostRecentTermMealsToRate({
    termsToRate: getTermsToRate({
      cookHistory: ovenCookHistory,
      mealReviewSummaries,
      orderHistoryReceipts: receipts,
    }),
  })

  const mealSuggestionsForMenu = getMealSuggestionsForMenu({
    mealSuggestions,
    mealSummaries,
  })

  const menuHasListings =
    menuListingsLayout.sections &&
    menuListingsLayout.sections.some((section) => section?.listings.length > 0)
  const selectedMenuID = selectedUserTerm.selectedSubTerm?.mainMenu.id

  const { data: listingSelectionsResponse } = useListingSelections({
    menuID: selectedMenuID,
    userID: user.id,
  })
  const listingsSelections = listingSelectionsResponse?.listingsSelected ?? []
  const listingsTotalPriceCents =
    listingSelectionsResponse?.estimatedTotalCents ?? 0
  const listingsSelectionIDs = listingsSelections.map((listing) => listing.id)
  const selectedListings = flatMap(menuListingsLayout.sections, (section) => {
    return section ? section.listings : []
  }).filter((listing) => {
    return listingsSelectionIDs.includes(listing.id)
  })
  const totalListingSelections = !selectedUserTerm.isSkipped
    ? listingsSelections.reduce((total, selection) => {
        return total + selection.quantity
      }, 0)
    : 0

  const [confirmationDialogData, setConfirmationDialogData] =
    useState<ConfirmationDialogData | null>(null)
  const [selectedMealFilters, setSelectedMealFilters] =
    useState<SelectedMealFilters>(() => {
      if (storageAvailable('localStorage')) {
        const mealFilters = localStorage.getItem('mealFilters')

        return mealFilters ? JSON.parse(mealFilters) : {}
      }

      return {}
    })
  const [showRateMealsCarousel, setShowRateMealsCarousel] = useState(false)
  const [showRateMealsPrompt, setShowRateMealsPrompt] = useState(true)

  const { closeOrderSummary, openOrderSummary, orderSummarySidebarStatus } =
    useOrderSummarySidebar({
      hasSelectedAllMeals: selectedUserTerm.hasSelectedAllMeals,
    })

  const [state, send] = useMachine(
    () => {
      return createMenuMachine({
        termID: selectedUserTerm.termID,
        userID: user.id,
      })
    },
    {
      actions: {
        showAddListingError: (_ctx, event) => {
          const err = event.data

          if (err instanceof Error && isCombinedAPIResponseError(err)) {
            if (err.response?.data.message === 'listing is sold out') {
              openToast({
                goodErrorMessaging: {
                  wayOut: null,
                  whatHappened: 'Extra is sold out',
                  why: "We couldn't add this extra because it's sold out.",
                },
                type: 'error',
              })

              return
            }
          }

          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try adding the extra again.',
              whatHappened: 'Unable to add extra',
              why: "We couldn't add this extra due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showAddMealError: (_ctx, event) => {
          const err = event.data

          if (err instanceof Error && isCombinedAPIResponseError(err)) {
            if (err.response?.data.message === 'BlackSheetTrayRequired') {
              openToast({
                goodErrorMessaging: {
                  helpToFix: 'Please try again.',
                  wayOut: wrapWithContactSupportTeam(
                    'If you need further assistance'
                  ),
                  whatHappened: 'Unable to add meal',
                  why: "We couldn't add this meal because it requires a Tovala Sheet Tray and your account does not indicate you have one.",
                },
                type: 'error',
              })

              return
            } else if (err.response?.data.message === 'MealSoldOut') {
              openToast({
                goodErrorMessaging: {
                  wayOut: null,
                  whatHappened: 'Meal is sold out',
                  why: "We couldn't add this meal because it's sold out.",
                },
                type: 'error',
              })

              return
            } else if (err.response?.data.message === 'UserSkippedTerm') {
              openToast({
                goodErrorMessaging: {
                  wayOut:
                    'Please unskip this week and try adding the meal again.',
                  whatHappened: 'Week Skipped',
                  why: "We couldn't add this meal because you've skipped this week.",
                },
                type: 'error',
              })

              return
            }
          }

          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try adding the meal again.',
              whatHappened: 'Unable to add meal',
              why: "We couldn't add this meal due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showAddOptionError: (_ctx, event) => {
          const err = event.data

          if (err instanceof Error && isCombinedAPIResponseError(err)) {
            if (err.response?.data.message === 'BlackSheetTrayRequired') {
              openToast({
                goodErrorMessaging: {
                  helpToFix: 'Please try again.',
                  wayOut: wrapWithContactSupportTeam(
                    'If you need further assistance'
                  ),
                  whatHappened: 'Unable to add meal',
                  why: "We couldn't add this extra because it requires a Tovala Sheet Tray and your account does not indicate you have one.",
                },
                type: 'error',
              })

              return
            } else if (err.response?.data.message === 'MealSoldOut') {
              openToast({
                goodErrorMessaging: {
                  wayOut: null,
                  whatHappened: 'Extra is sold out',
                  why: "We couldn't add this extra because it's sold out.",
                },
                type: 'error',
              })

              return
            } else if (err.response?.data.message === 'UserSkippedTerm') {
              openToast({
                goodErrorMessaging: {
                  wayOut:
                    'Please unskip this week and try adding the extra again.',
                  whatHappened: 'Week Skipped',
                  why: "We couldn't add this extra because you've skipped this week.",
                },
                type: 'error',
              })

              return
            }
          }
          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try adding the extra again.',
              whatHappened: 'Unable to add extra',
              why: "We couldn't add this extra due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showRemoveListingError: () => {
          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try removing the extra again.',
              whatHappened: 'Unable to remove extra',
              why: "We couldn't remove this extra due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showRemoveMealError: () => {
          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try removing the meal again.',
              whatHappened: 'Unable to remove meal',
              why: "We couldn't remove this meal due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showRemoveOptionError: () => {
          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try removing the meal extra.',
              whatHappened: 'Unable to remove extra',
              why: "We couldn't remove this extra due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        trackConfirmedUpgrade: () => {
          track(events.USER_CONFIRMED_UPSELL)
        },
        trackFinishedMealSelection: (ctx) => {
          track(events.FINISHES_MEAL_SELECTION, {
            term_id: ctx.termID,
          })
        },
        trackMealAdded: (ctx, event) => {
          const { addMealMeta, termID } = ctx
          const { termStatus } = event.data

          if (addMealMeta) {
            trackMealModified({
              maxSelections: termStatus.subscriptionType?.maxSelections ?? 0,
              mealID: addMealMeta.mealID,
              mealSelectionEvent: addMealMeta.mealSelectionEvent,
              numSelections: termStatus.mealSelections.length,
              termID,
            })
          }
        },
        trackMealOptionAdded: (ctx) => {
          const { termID, addMealOptionMeta } = ctx

          if (addMealOptionMeta) {
            track(events.TAPPED_ADD_MEAL_EXTRA, {
              meal_id: addMealOptionMeta.mealOption,
              term_id: termID,
            })
          }
        },
        trackMealOptionRemoved: (ctx) => {
          const { termID, removeMealOptionMeta } = ctx

          if (removeMealOptionMeta) {
            track(events.TAPPED_REMOVE_MEAL_EXTRA, {
              meal_id: removeMealOptionMeta.mealOption,
              term_id: termID,
            })
          }
        },
        trackMealRemoved: (ctx, event) => {
          const { removeMealMeta, termID } = ctx

          if (removeMealMeta) {
            trackMealModified({
              maxSelections: removeMealMeta.maxSelections,
              mealID: removeMealMeta.mealID,
              mealSelectionEvent: removeMealMeta.mealSelectionEvent,
              numSelections: event.data.mealSelections.length,
              termID,
            })
          }
        },
      },
      guards: {
        hasFewerMealsThanNextPlanMax: () => {
          return (
            !!selectedUserTerm.nextLargestSubscriptionType &&
            selectedUserTerm.nextLargestSubscriptionType.maxSelections >
              mealSelectionIDs.length
          )
        },
        hasFewerThanMaxMeals: () => {
          return selectedUserTerm.nextLargestSubscriptionType !== null
        },
        hasMaxMeals: (_ctx, event) => {
          const termStatus = event.data.termStatus
          const numMealsToAdd = termStatus.subscriptionType
            ? termStatus.subscriptionType.maxSelections -
              termStatus.mealSelections.length
            : 0

          return numMealsToAdd === 0
        },
        hasSelectedAllMeals: () => {
          return selectedUserTerm.hasSelectedAllMeals
        },
        hasSelectionsOfMeal: (_ctx, event) => {
          const mealID = event.mealID
          return selectedUserTerm.mealSelections
            .map((mealSelection) => mealSelection.mealID)
            .includes(mealID)
        },
        hasSelectionsOfMealOption: (_ctx, event) => {
          const mealID = Number(event.mealOption)
          return selectedUserTerm.mealSelections
            .map((mealSelection) => mealSelection.mealID)
            .includes(mealID)
        },
      },
      services: {
        addListing: (ctx, event) => {
          if (!selectedMenuID) {
            throw new Error('Cannot select an extra without a menu ID.')
          }

          const { userID } = ctx

          return addListingSelectionAsync({
            data: {
              listingID: event.listingID,
            },
            listingID: event.listingID,
            menuID: selectedMenuID,
            userID,
          })
        },
        addMeal: (ctx, event) => {
          const { termID, userID } = ctx

          return addMealSelectionAsync({
            data: {
              mealid: event.mealID,
              termid: termID,
              userid: userID,
            },
            userID,
          })
        },
        addMealOption: (ctx, event) => {
          const { userID } = ctx

          return updateMealSelectionOptionAsync({
            data: {
              mealID: event.mealID,
              mealOption: event.mealOption,
            },
            subTermID: selectedUserTerm.selectedSubTermID,
            userID,
          })
        },
        removeListing: (ctx, event) => {
          if (!selectedMenuID) {
            throw new Error('Cannot select an extra without a menu ID.')
          }

          const { userID } = ctx

          return deleteListingSelectionAsync({
            listingID: event.listingID,
            menuID: selectedMenuID,
            userID,
          })
        },
        removeMeal: (ctx, event) => {
          const { termID, userID } = ctx

          if (!event.selectionID) {
            throw new Error('No meal selection ID specified for removal.')
          }

          return deleteMealSelectionAsync({
            selectionID: event.selectionID,
            termID,
            userID,
          })
        },
        removeMealOption: (ctx, event) => {
          const { userID } = ctx

          return updateMealSelectionOptionAsync({
            data: {
              mealID: event.mealID,
              mealOption: null,
              currentMealOption: event.mealOption,
            },
            subTermID: selectedUserTerm.selectedSubTermID,
            userID,
          })
        },
        updateTermStatus: async () => {
          if (!selectedUserTerm.nextLargestSubscriptionType) {
            throw new Error('No next subscription type for upgrade.')
          }

          return await upsertUserTermStatus({
            data: {
              notes: '',
              subscriptionTypeID:
                selectedUserTerm.nextLargestSubscriptionType.id,
              subTermID: selectedUserTerm.selectedSubTermID,
              termID: selectedUserTerm.termID,
              userID: user.id,
            },
            selectedUserTermID: selectedUserTerm.id,
            userID: user.id,
          })
        },
        savePendingMeal: async (ctx) => {
          return await addMealSelectionAsync({
            data: {
              mealid: ctx.pendingMealIDs[ctx.nextPendingMealIndex],
              termid: selectedUserTerm.termID,
              userid: user.id,
            },
            userID: user.id,
          })
        },
      },
    }
  )

  const { nextMaxSelections, pendingMealIDs } = state.context
  const pendingMealSelections = compact(
    pendingMealIDs.map((mealID) => {
      return mealSummaries.find(({ id }) => id === mealID)
    })
  )
  const mealSelectionIDs = getMealSelectionIDs({
    pendingMealSelections,
    selectedUserTerm,
  })
  const selectedMeals = mealSummaries.filter((meal) => {
    return mealSelectionIDs.includes(meal.id)
  })

  const {
    error: upsertUserTermStatusError,
    isError: hasUpsertUserTermStatusError,
    mutateAsync: upsertUserTermStatus,
  } = useUpsertUserTermStatus()

  const { mutateAsync: addMealSelectionAsync } = useAddMealSelection()

  const { mutateAsync: deleteMealSelectionAsync } = useDeleteMealSelection()

  const { mutateAsync: updateMealSelectionOptionAsync } =
    useUpdateMealSelectionOption()

  const { mutateAsync: addListingSelectionAsync } = useAddListingSelection()

  const { mutateAsync: deleteListingSelectionAsync } =
    useDeleteListingSelection()

  const showOrderUpgrade =
    state.matches('upgrading') || pendingMealIDs.length > 0
  const isUpgrading = state.matches('upgrading.savingUpgrades')
  const isUpdatingListingSelections =
    state.matches('addingListing') || state.matches('removingListing')
  const isUpdatingMealSelections =
    state.matches('addingMeal') ||
    state.matches('addingMealOption') ||
    state.matches('removingMeal') ||
    state.matches('removingMealOption') ||
    state.matches('upgrading.removingMeal')
  const canDecrementListing = !isUpdatingListingSelections
  const canDecrementMeal = !isUpdatingMealSelections
  const canAddMoreMealsForUpgrade =
    !!selectedUserTerm.nextLargestSubscriptionType &&
    mealSelectionIDs.length <
      selectedUserTerm.nextLargestSubscriptionType.maxSelections
  const canIncrementListing = !isUpdatingListingSelections
  const canIncrementMeal =
    (!selectedUserTerm.hasSelectedAllMeals || canAddMoreMealsForUpgrade) &&
    !isUpdatingMealSelections

  const { data: mealFilters = [], isLoading: isLoadingMealFilters } =
    useGetMealFilters()
  const { data: mealsInFilters = [], isLoading: isLoadingMealsInFilters } =
    useMealsInFilters({
      subTermID: selectedUserTerm.selectedSubTermID,
    })

  const handleMealSelectionConfirmationDialogs = async ({
    onConfirm,
    meal,
  }: {
    onConfirm(): void
    meal: MealSummary
  }) => {
    const oilWarning = getTagOilWarning({ meal })
    const shouldShowOilWarning = oilWarning && !hasViewedOilWarning(meal)

    if (
      features.showSurchargePrompt &&
      meal.surchargeCents > 0 &&
      !planPreferences.premiumMealsOk
    ) {
      setConfirmationDialogData({ meal, onConfirm, type: 'surcharge' })
      return
    }

    if (getTagBST({ meal }) && !planPreferences.hasBlackSheetTray) {
      // If user is a new customer, skip showing the confirmation since they'll have a tray
      // that came with their oven.
      if (!hasPastOrders) {
        /* Requires Cooking Oil warning is appended to sheet tray confirmation, but if
          we're not showing the sheet tray confirmation, show the oil warning instead */
        if (shouldShowOilWarning) {
          setConfirmationDialogData({ meal, onConfirm, type: 'oilWarning' })
          return
        }

        const updatedPreferences = { ...planPreferences }
        updatedPreferences.hasBlackSheetTray = true

        try {
          await updatePlanPreferences({
            data: updatedPreferences,
            userID: user.id,
          })
        } catch (err) {
          // We fail silently here since updating the plan preferences to indicate
          // the user has a Tovala sheet tray is not a crucial workflow update and
          // can be done at a later time.
        } finally {
          onConfirm()
        }
        return
      }

      setConfirmationDialogData({ meal, onConfirm, type: 'blackSheetTray' })
      return
    }

    if (shouldShowOilWarning) {
      setConfirmationDialogData({ meal, onConfirm, type: 'oilWarning' })
      return
    }

    onConfirm()
  }

  const incrementListing = ({ listingID }: { listingID: string }) => {
    send({
      listingID,
      type: 'ADD_LISTING',
    })
  }

  const onClickIncrementListing = ({
    listingID,
    onClickEventSourceID = sourceIDs.EXTRAS_SCREEN,
  }: {
    listingID: string
    onClickEventSourceID?: 'extras_screen' | 'order_summary'
  }) => {
    track(events.DID_TAP_EXTRA_INCREMENT, {
      listing_id: listingID,
      source_id: onClickEventSourceID,
    })

    let extrasNotFilteredViewed: string | null = null

    if (storageAvailable('localStorage')) {
      extrasNotFilteredViewed = localStorage.getItem('extrasNotFilteredViewed')
    }

    if (!isEmpty(selectedMealFilters) && !extrasNotFilteredViewed) {
      if (storageAvailable('localStorage')) {
        localStorage.setItem('extrasNotFilteredViewed', 'true')
      }

      setConfirmationDialogData({
        onConfirm: () => {
          incrementListing({ listingID })
          setConfirmationDialogData(null)
        },
        type: 'noListingFilters',
      })
    } else {
      incrementListing({ listingID })
    }
  }

  const incrementMeal = ({
    meal,
    mealSelectionEvent = events.MODIFY_MEAL_SELECTION,
  }: {
    meal: MealSummary
    mealSelectionEvent?: AnalyticsEvent
  }) => {
    send({
      mealID: meal.id,
      mealSelectionEvent,
      nextMaxSelections:
        selectedUserTerm.nextLargestSubscriptionType?.maxSelections,
      type: 'ADD_MEAL',
    })
  }

  const onClickIncrementMeal = async ({
    meal,
    mealSelectionEvent = events.MODIFY_MEAL_SELECTION,
    onClickEventSourceID = sourceIDs.MENU_TERM,
  }: {
    meal: MealSummary
    mealSelectionEvent?: AnalyticsEvent
    onClickEventSourceID?: 'menu_term' | 'order_summary'
  }) => {
    track(events.DID_TAP_MEAL_INCREMENT, {
      meal_id: meal.id,
      source_id: onClickEventSourceID,
      term_id: selectedUserTerm.termID,
    })

    handleMealSelectionConfirmationDialogs({
      meal,
      onConfirm: () => {
        incrementMeal({ meal, mealSelectionEvent })
      },
    })
  }

  const deselectMealOption = ({
    meal,
    mealOption,
  }: {
    meal: MealSummary
    mealOption: string
  }) => {
    send({
      mealID: meal.id,
      mealOption,
      type: 'REMOVE_MEAL_OPTION',
    })
  }

  const onClickDeselectMealOption = ({
    meal,
    mealOption,
  }: {
    meal: MealSummary
    mealOption: MealSummary
  }) => {
    deselectMealOption({ meal, mealOption: `${mealOption.id}` })
  }

  const selectMealOption = ({
    meal,
    mealOption,
  }: {
    meal: MealSummary
    mealOption: string
  }) => {
    send({
      mealID: meal.id,
      mealOption,
      type: 'ADD_MEAL_OPTION',
    })
  }

  const onClickSelectMealOption = ({
    meal,
    mealOption,
  }: {
    meal: MealSummary
    mealOption: MealSummary
  }) => {
    handleMealSelectionConfirmationDialogs({
      meal: mealOption,
      onConfirm: () => {
        selectMealOption({ meal, mealOption: `${mealOption.id}` })
      },
    })
  }

  const planPreferences = getSubscriptionPreferences({ user })
  const { mutateAsync: updatePlanPreferences } = useUpdatePlanPreferences()

  const decrementListing = ({ listingID }: { listingID: string }) => {
    send({
      listingID,
      type: 'REMOVE_LISTING',
    })
  }

  const onClickDecrementListing = ({
    listingID,
    onClickEventSourceID = sourceIDs.EXTRAS_SCREEN,
  }: {
    listingID: string
    onClickEventSourceID?: 'extras_screen' | 'order_summary'
  }) => {
    track(events.DID_TAP_EXTRA_DECREMENT, {
      listing_id: listingID,
      source_id: onClickEventSourceID,
    })

    decrementListing({ listingID })
  }

  const decrementMeal = ({
    mealID,
    mealSelectionEvent = events.MODIFY_MEAL_SELECTION,
  }: {
    mealID: number
    mealSelectionEvent?: AnalyticsEvent
  }) => {
    const quantity = mealSelectionIDs.filter((x) => x === mealID).length

    if (selectedUserTerm.subscriptionType && quantity > 0) {
      const selections = [...selectedUserTerm.mealSelections].sort((a, b) => {
        return +new Date(b.created) - +new Date(a.created)
      })
      const mealSelectionToDelete = selections.find(
        (selection) => selection.mealID === mealID
      )

      send({
        maxSelections: selectedUserTerm.subscriptionType.maxSelections,
        mealID,
        mealSelectionEvent,
        selectionID: mealSelectionToDelete?.id,
        type: 'REMOVE_MEAL',
      })
    }
  }

  const onClickDecrementMeal = ({
    mealID,
    mealSelectionEvent = events.MODIFY_MEAL_SELECTION,
    onClickEventSourceID = sourceIDs.MENU_TERM,
  }: {
    mealID: number
    mealSelectionEvent?: AnalyticsEvent
    onClickEventSourceID?: 'menu_term' | 'order_summary'
  }) => {
    track(events.DID_TAP_MEAL_DECREMENT, {
      meal_id: mealID,
      source_id: onClickEventSourceID,
      term_id: selectedUserTerm.termID,
    })

    decrementMeal({ mealID, mealSelectionEvent })
  }

  // Outside actions can cause the user to have all their meals selected, such as
  // editing their subscription type to be a smaller number of meals.
  useEffect(() => {
    if (selectedUserTerm.hasSelectedAllMeals) {
      send({ type: 'ALL_MEALS_SELECTED' })
    }
  }, [send, selectedUserTerm.hasSelectedAllMeals])

  const onChangeSelectedMealFilters = (filters: SelectedMealFilters) => {
    setSelectedMealFilters(filters)

    if (storageAvailable('localStorage')) {
      if (isEmpty(filters)) {
        localStorage.removeItem('mealFilters')
      } else {
        localStorage.setItem('mealFilters', JSON.stringify(filters))
      }
    }

    handleMealSelectionsOutsideFilters({ selectedMealFilters: filters })
  }

  const handleMealSelectionsOutsideFilters = ({
    selectedMealFilters,
  }: {
    selectedMealFilters: SelectedMealFilters
  }) => {
    const filteredAllergenMealSelections = getFilteredAllergenMealSelections({
      mealSelections: selectedUserTerm.mealSelections,
      mealsInFilters,
      selectedMealFilters,
    })

    if (filteredAllergenMealSelections.length > 0) {
      setConfirmationDialogData({
        type: 'filteredAllergens',
      })
    }
  }

  useTrackScrollDepth({
    maxSelections: selectedUserTerm.subscriptionType?.maxSelections,
    numMeals: mealSummaries.length,
    numSelections: selectedUserTerm.mealSelections.length,
    termID: selectedUserTerm.termID,
  })

  const resolvedMenuComponents = getMenuMealComponents({
    mealSwaps: selectedUserTerm.mealSwaps,
    meals: mealSummaries,
    menuComponents: components,
    specialEvent:
      selectedUserTerm.selectedSubTerm?.specialEvent ||
      selectedUserTerm.specialEvent,
    suggestions: mealSuggestionsForMenu,
  })

  const { everythingElse, filteredComponents, mealIDsInFilters } =
    getFilteredMenuComponents({
      allMealIDs: mealSummaries.map(({ id }) => id),
      selectedMealFilters,
      mealFilters,
      mealsInFilters,
      menuComponents: resolvedMenuComponents,
    })

  const numFilteredResults = getFilteredComponentsCounts({
    filteredComponents,
  })

  // "selectedMealFilters" can be an empty object or it can be an object
  // like { "Dietary Preferences": boolean | string[] }.
  const hasSelectedFilters =
    !isEmpty(selectedMealFilters) &&
    some(
      selectedMealFilters,
      (filterKeys) => typeof filterKeys !== 'boolean' && filterKeys.length > 0
    )

  const canModifySelections = !selectedUserTerm.isSkipped

  const commonMenuComponentGridProps = {
    canDecrement: canDecrementMeal,
    canIncrement: canIncrementMeal,
    canModifySelections,
    mealIDsInFilters,
    mealSelectionIDs,
    onClickDecrement: onClickDecrementMeal,
    onClickDeselectMealOption,
    onClickIncrement: onClickIncrementMeal,
    onClickMeal: (mealID: number) => {
      openDetailsDialog({ type: 'meal', id: mealID })
    },
    onClickSelectMealOption,
    setConfirmationDialogData,
    termID: selectedUserTerm.termID,
  }

  const commonOrderStatusProps = {
    closeOrderSummary,
    isSelectingListings: selectedMenuTab === 'extras',
    isUpgrading,
    listingsSelections,
    nextMaxSelections,
    onClickContinue: () => {
      menuTabs.selectTab('extras')
    },
    onClickDone: (openSource: 'orderSummary' | undefined = undefined) => {
      setConfirmationDialogData({ openSource, type: 'mealsSelected' })
    },
    onClickSkipAdjust: () => {
      onEditTermStatus(selectedUserTerm)
    },
    onConfirmUpgrade: () => {
      send({ type: 'SAVE_PENDING_MEALS' })
    },
    openOrderSummary: () => {
      openOrderSummary()
    },
    orderSummarySidebarStatus,
    pendingMealSelections,
    selectedUserTerm,
    showOrderUpgrade,
    upgradeOrderError: hasUpsertUserTermStatusError
      ? upsertUserTermStatusError
      : null,
  }

  const isLoadingNeededFilters =
    hasSelectedFilters && (isLoadingMealFilters || isLoadingMealsInFilters)

  // If the user has selected filters, we want to wait until the filter data
  // loads so we avoid a flash of inaccurate meal displays (in the case where
  // meal summaries load before filter data does).
  const canDisplayMenu =
    selectedUserTerm.readyForView &&
    mealSummaries.length > 0 &&
    !isLoadingNeededFilters

  const { isNoMenuTerm, specialEvent } = useIsNoMenuTerm({
    term: selectedUserTerm,
  })

  if (isNoMenuTerm) {
    return (
      <NoMenuTerm message={specialEvent} selectedUserTerm={selectedUserTerm} />
    )
  }

  if (
    features.showStaticSkipAsNoMenu &&
    selectedUserTerm.termID === 417 &&
    selectedUserTerm.isStaticSkipped
  ) {
    return (
      <NoMenuTerm
        message={
          "We are unable to ship you meals this week, as New Year's affects our shipping partner's ability to deliver meals to your location on time."
        }
        selectedUserTerm={selectedUserTerm}
      />
    )
  }

  return (
    <>
      {selectedUserTerm && (
        <>
          {selectedUserTerm.readyForView ? (
            <MenuTabGroup
              menuHeader={({ isHeaderSticky }) => {
                return (
                  <MenuHeader
                    backButton={
                      <Button
                        aria-label="My Orders"
                        buttonStyle="link"
                        onClick={() => {
                          navigate('/my-orders')

                          track(events.MENU_BACK_CTA)

                          const termMealCount =
                            showOrderUpgrade &&
                            selectedUserTerm.nextLargestSubscriptionType
                              ? selectedUserTerm.nextLargestSubscriptionType
                                  .maxSelections
                              : !showOrderUpgrade &&
                                selectedUserTerm.subscriptionType
                              ? selectedUserTerm.subscriptionType.maxSelections
                              : ''

                          track(events.EXITS_MENU, {
                            meal_selection_count:
                              selectedUserTerm.mealSelections.length,
                            meal_selection_max: termMealCount,
                          })
                        }}
                        size="auto"
                      >
                        <div className="mr-2 h-6 w-6">
                          <ArrowLeftIcon />
                        </div>
                        <span className="text-k/16_125 md:hidden">
                          My Orders
                        </span>
                      </Button>
                    }
                    filters={
                      selectedMenuTab === 'meals' ? (
                        <MenuFilters
                          onChangeSelectedMealFilters={
                            onChangeSelectedMealFilters
                          }
                          renderButton={({ openSidebar }) => {
                            return (
                              <Button
                                aria-label="Filter"
                                buttonStyle="link"
                                onClick={openSidebar}
                                size="auto"
                              >
                                <div className="mr-2 h-6 w-6">
                                  <FilterIcon />
                                </div>
                                <span className="text-k/16_125 md:hidden">
                                  Filter
                                </span>
                              </Button>
                            )
                          }}
                          selectedMealFilters={selectedMealFilters}
                          selectedUserTerm={selectedUserTerm}
                        />
                      ) : null
                    }
                    isHeaderSticky={isHeaderSticky}
                    selectedUserTerm={selectedUserTerm}
                  />
                )
              }}
              menuTabs={menuTabs}
              tabChangeEventProps={{
                maxSelections: selectedUserTerm.isSkipped
                  ? selectedUserTerm.subscriptionType?.maxSelections ?? 0
                  : -1,
                selectionsCount: selectedUserTerm.isSkipped
                  ? selectedUserTerm.mealSelections.length +
                    pendingMealIDs.length
                  : -1,
                termID: selectedUserTerm.termID,
              }}
              totalListingSelections={totalListingSelections}
            >
              <MealsTabPanel>
                {canDisplayMenu && (
                  <div className="pb-52 pt-20 md:pb-40">
                    {hasSelectedFilters && (
                      <FilterMealGroup>
                        <div className="mx-auto max-w-menu lg:px-4 md:px-0">
                          <div className="space-y-10 md:space-y-6">
                            <FilterGroupTitle
                              description={
                                numFilteredResults === 0
                                  ? 'No meals match your filter criteria'
                                  : ''
                              }
                              filters={
                                <MenuFilters
                                  onChangeSelectedMealFilters={
                                    onChangeSelectedMealFilters
                                  }
                                  renderButton={({ openSidebar }) => {
                                    return (
                                      <Button
                                        buttonStyle="stroke"
                                        onClick={openSidebar}
                                        size="small"
                                      >
                                        Edit Filter
                                      </Button>
                                    )
                                  }}
                                  selectedMealFilters={selectedMealFilters}
                                  selectedUserTerm={selectedUserTerm}
                                />
                              }
                              numResults={numFilteredResults}
                              title="My Filtered Meals"
                            />

                            <MenuComponentsGrid
                              {...commonMenuComponentGridProps}
                              components={filteredComponents}
                            />
                          </div>
                        </div>
                      </FilterMealGroup>
                    )}
                    <FilterMealGroup>
                      <div className="mx-auto max-w-menu lg:px-4 md:px-0">
                        <div className="space-y-10 md:space-y-6">
                          {hasSelectedFilters && (
                            <FilterGroupTitle
                              description="Meals outside your filter criteria"
                              title="Everything Else"
                            />
                          )}
                          <MenuComponentsGrid
                            {...commonMenuComponentGridProps}
                            components={everythingElse}
                          />
                        </div>
                      </div>
                    </FilterMealGroup>
                  </div>
                )}
              </MealsTabPanel>

              <ExtrasTabPanel>
                <div className="mx-auto max-w-menu pb-52 pt-20 lg:px-4 md:px-0 md:pb-40">
                  {menuHasListings ? (
                    <>
                      <ExtrasGrid
                        canModifySelections={canModifySelections}
                        disabledDecrement={!canDecrementListing}
                        disabledIncrement={!canIncrementListing}
                        listingsSelections={listingsSelections}
                        menuListingsLayout={menuListingsLayout}
                        onClickDecrement={onClickDecrementListing}
                        onClickIncrement={onClickIncrementListing}
                        onClickListing={(listingID: string) => {
                          openDetailsDialog({
                            id: listingID,
                            type: 'listing',
                          })

                          track(events.OPENS_EXTRA_DETAIL, {
                            listing_id: listingID,
                          })
                        }}
                      />

                      <div className="md:px-4">
                        <div className="mx-auto mt-16 max-w-[512px] rounded-lg bg-white px-10 py-4 md:px-4">
                          <ExtrasAllergenNote />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="md:px-4">
                      <div className="mx-auto mt-16 max-w-[512px] rounded-lg bg-white px-10 py-4 md:px-4">
                        <div className="space-y-4 text-center md:text-left">
                          <h2 className="text-k/20_110">No Extras This Week</h2>
                          <p className="text-k/16_125 text-grey-9 md:text-body-sm">
                            Although you can usually find extras here, there are
                            none this week. Please check on another menu to find
                            extras.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </ExtrasTabPanel>
            </MenuTabGroup>
          ) : (
            <MenuUnavailable
              menuHeader={
                <MenuHeader
                  backButton={
                    <Button
                      aria-label="My Orders"
                      buttonStyle="link"
                      onClick={() => {
                        navigate('/my-orders')
                      }}
                      size="auto"
                    >
                      <div className="mr-2 h-6 w-6">
                        <ArrowLeftIcon />
                      </div>
                      <span className="text-k/16_125 md:hidden">My Orders</span>
                    </Button>
                  }
                  isHeaderSticky={false}
                  selectedUserTerm={selectedUserTerm}
                />
              }
            />
          )}

          <MenuFixedBottomContainer
            banner={
              <CreditsBanner
                totalCreditsCents={getTotalCreditsCents({ user })}
              />
            }
          >
            <OrderStatus {...commonOrderStatusProps} />
          </MenuFixedBottomContainer>
        </>
      )}

      {detailsType === 'meal' && meal ? (
        <MealDetailsDialog
          closeModal={closeDetailsDialog}
          meal={meal}
          suggestions={mealSuggestionsForMenu}
        />
      ) : detailsType === 'listing' && listing ? (
        <ListingDetailsDialog listing={listing} onClose={closeDetailsDialog} />
      ) : null}

      {orderSummarySidebarStatus.isOpen && (
        <OrderSummarySidebar
          listings={selectedListings.map((listing) => {
            const quantity =
              listingsSelections.find(
                (listingSelection) => listingSelection.id === listing.id
              )?.quantity ?? 0
            return (
              <OrderSummaryExtra
                key={`order-summary-${listing.id}`}
                listing={listing}
                stepper={
                  <QuantityStepper
                    disabledDecrement={!canDecrementMeal}
                    disabledIncrement={!canIncrementMeal || listing.isSoldOut}
                    labelDecrement="Remove Meal"
                    labelIncrement="Add Meal"
                    min={0}
                    onClickDecrement={() => {
                      onClickDecrementListing({
                        listingID: listing.id,
                        onClickEventSourceID: sourceIDs.ORDER_SUMMARY,
                      })
                    }}
                    onClickIncrement={() => {
                      onClickIncrementListing({
                        listingID: listing.id,
                        onClickEventSourceID: sourceIDs.ORDER_SUMMARY,
                      })
                    }}
                    quantity={quantity}
                    size="small"
                  />
                }
              />
            )
          })}
          listingsTotalPriceCents={listingsTotalPriceCents}
          meals={selectedMeals.map((meal) => {
            const quantity = mealSelectionIDs.filter(
              (x) => x === meal.id
            ).length

            return (
              <OrderSummaryMeal
                key={`order-summary-${meal.id}`}
                meal={meal}
                mealStepper={
                  <QuantityStepper
                    disabledDecrement={!canDecrementMeal}
                    disabledIncrement={!canIncrementMeal || meal.isSoldOut}
                    labelDecrement="Remove Meal"
                    labelIncrement="Add Meal"
                    min={0}
                    onClickDecrement={() => {
                      onClickDecrementMeal({
                        onClickEventSourceID: sourceIDs.ORDER_SUMMARY,
                        mealID: meal.id,
                      })
                    }}
                    onClickIncrement={() => {
                      onClickIncrementMeal({
                        onClickEventSourceID: sourceIDs.ORDER_SUMMARY,
                        meal,
                      })
                    }}
                    quantity={quantity}
                    size="small"
                  />
                }
              />
            )
          })}
          onClickPickMeals={() => {
            menuTabs.selectTab('meals')
            closeOrderSummary()
          }}
          onClickSkipAdjust={() => {
            onEditTermStatus(selectedUserTerm)
          }}
          onCloseSidebar={() => {
            closeOrderSummary()
          }}
          orderStatus={
            <MenuFixedBottomContainer>
              <OrderStatus {...commonOrderStatusProps} />
            </MenuFixedBottomContainer>
          }
          selectedUserTerm={selectedUserTerm}
          showOrderUpgrade={showOrderUpgrade}
        />
      )}

      {isSubscriptionActive && customer && !hasPaymentSource && (
        <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center bg-white p-10">
          <h4 className="mb-4 text-k/20_125">
            Please add a payment method on the Account page to receive meals.
          </h4>

          <Button onClick={() => navigate('/account/payment')} size="large">
            Add Payment Method
          </Button>
        </div>
      )}
      {showRateMealsPrompt && (
        <div className="fixed bottom-2 left-2 z-20 max-w-[180px] lg:hidden">
          <RateMealsPrompt
            mostRecentTermMealsToRate={mostRecentTermMealsToRate}
            onClickClose={() => setShowRateMealsPrompt(false)}
          />
        </div>
      )}
      {showRateMealsCarousel && mostRecentTermMealsToRate && (
        <RateMealsCarousel
          meals={mostRecentTermMealsToRate.meals}
          onClose={() => setShowRateMealsCarousel(false)}
        />
      )}
      {confirmationDialogData && (
        <ConfirmationDialogs
          confirmationDialogData={confirmationDialogData}
          hasBlackSheetTray={planPreferences.hasBlackSheetTray}
          onChangeConfirmationDialog={setConfirmationDialogData}
          onClickViewSummary={() => {
            setConfirmationDialogData(null)
            openOrderSummary('selectionsConfirmation')
          }}
          onClickWantMoreMeals={() => {
            if (menuHasListings) {
              navigate(`/menu/${selectedUserTerm.termID}`)
            }
          }}
          onClose={() => {
            setConfirmationDialogData(null)
          }}
          onDecrementMeal={decrementMeal}
          selectedUserTerm={selectedUserTerm}
          totalOrderCount={totalOrderCount}
        />
      )}
    </>
  )
}

export default Menu

const FilterMealGroup = ({ children }: { children: ReactNode }) => {
  return (
    <div className="py-10 first:pt-0 last:pb-0 even:bg-white even:pb-10 md:py-6">
      {children}
    </div>
  )
}

const FilterGroupTitle = ({
  description,
  filters,
  numResults,
  title,
}: {
  description?: string
  filters?: ReactNode
  numResults?: number
  title: string
}) => {
  return (
    <div className="space-y-3 md:px-4">
      <div className="flex items-center justify-between">
        <h2 className="text-k/36_110 md:text-k/26_110">{title}</h2>

        {filters}
      </div>

      <div className="space-y-10">
        {numResults !== undefined && (
          <p className="text-k/20_125 text-grey-9 md:text-k/18_120">
            {numResults} results
          </p>
        )}

        {description && (
          <div className="text-k/20_125 text-grey-9 md:text-k/18_120">
            {description}
          </div>
        )}
      </div>
    </div>
  )
}

export const ReleaseFromCartConfirmation = ({
  onClickClose,
  onClickConfirm,
}: {
  onClickClose(): void
  onClickConfirm(): void
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader heading="Release from cart?" />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">
            This meal is sold out. If you remove this meal, you may not be able
            to add it back.
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid grid-cols-2 gap-2">
          <Button buttonStyle="stroke" onClick={onClickClose} size="large">
            Cancel
          </Button>

          <Button onClick={onClickConfirm} size="large">
            OK
          </Button>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export const RequiresCookingOilConfirmation = ({
  oilWarning,
  onClose,
  onConfirm,
}: {
  oilWarning: MealTag
  onClose(): void
  onConfirm(): void
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClose}>
      <ConfirmationHeader heading={oilWarning.title} />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">
            {oilWarning.description}
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid grid-cols-2 gap-2">
          <Button buttonStyle="stroke" onClick={onClose} size="large">
            Cancel
          </Button>

          <Button onClick={onConfirm} size="large">
            Sounds good
          </Button>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

function useTrackScrollDepth({
  maxSelections,
  numMeals,
  numSelections,
  termID,
}: {
  maxSelections: number | undefined
  numMeals: number
  numSelections: number
  termID: number
}) {
  useEffect(() => {
    let scrollDepth = 0

    const trackScrollMenu = (event: AnalyticsEvent) => {
      track(event, {
        meal_selection_count: numSelections,
        meal_selection_max: maxSelections,
        term_id: termID,
      })
    }

    const throttleTrackScrollsMenu25 = throttle(trackScrollMenu, 2000, {
      trailing: false,
    })
    const throttleTrackScrollsMenu50 = throttle(trackScrollMenu, 2000, {
      trailing: false,
    })
    const throttleTrackScrollsMenu75 = throttle(trackScrollMenu, 2000, {
      trailing: false,
    })
    const throttleTrackScrollsMenu100 = throttle(trackScrollMenu, 2000, {
      trailing: false,
    })

    const onMenuScroll = () => {
      if (numMeals) {
        const scrollY = window.pageYOffset
        const scrollHeight =
          document.documentElement.scrollHeight - window.innerHeight
        const newScrollDepth = Math.round((scrollY / scrollHeight) * 100)
        scrollDepth = newScrollDepth

        if (scrollDepth === 25) {
          throttleTrackScrollsMenu25(events.SCROLLS_MENU_25)
        }

        if (scrollDepth === 50) {
          throttleTrackScrollsMenu50(events.SCROLLS_MENU_50)
        }

        if (scrollDepth === 75) {
          throttleTrackScrollsMenu75(events.SCROLLS_MENU_75)
        }

        if (scrollDepth === 100) {
          throttleTrackScrollsMenu100(events.SCROLLS_MENU_100)
        }
      }
    }
    window.addEventListener('scroll', onMenuScroll)

    return () => {
      window.removeEventListener('scroll', onMenuScroll)
    }
  }, [maxSelections, numMeals, numSelections, termID])
}

function trackMealModified({
  maxSelections,
  mealID,
  mealSelectionEvent,
  numSelections,
  termID,
}: {
  maxSelections: number
  mealID: number
  mealSelectionEvent: AnalyticsEvent
  numSelections: number
  termID: number
}) {
  track(mealSelectionEvent, {
    meal_id: mealID,
    meal_selection_diff:
      maxSelections > 0 ? maxSelections - numSelections : undefined,
    meal_selection_count: numSelections,
    meal_selection_max: maxSelections,
    term_id: termID,
  })
}

const NoMenuTerm = ({
  message,
  selectedUserTerm,
}: {
  message: string
  selectedUserTerm: UserTerm
}) => {
  const navigate = useNavigate()

  return (
    <div className="mx-auto max-w-menu space-y-6">
      <div className="pb-4 pt-10 lg:px-4 md:py-4">
        <MenuHeader
          backButton={
            <Button
              aria-label="My Orders"
              buttonStyle="link"
              onClick={() => {
                navigate('/my-orders')

                track(events.MENU_BACK_CTA)
              }}
              size="auto"
            >
              <div className="mr-2 h-6 w-6">
                <ArrowLeftIcon />
              </div>
              <span className="text-k/16_125 md:hidden">My Orders</span>
            </Button>
          }
          filters={null}
          isHeaderSticky={false}
          selectedUserTerm={selectedUserTerm}
        />
      </div>
      <div className="md:px-4">
        <SpecialEventBanner>{message}</SpecialEventBanner>
      </div>
    </div>
  )
}
