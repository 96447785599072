import { MealFilter, MealSelection } from '@tovala/browser-apis-combinedapi'
import { cloneDeep, intersection, partition } from 'lodash-es'

import {
  MenuComponentsStandardized,
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedMealCarousel,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedTextImageStackChildren,
  MenuComponentStandardizedTwoMealPicker,
} from '@tovala/browser-apis-menu-components'
import { SelectedMealFilters } from 'types/internal'
import {
  convertMealCarouselMealToBaseMeal,
  convertTwoMealPickerMealToBaseMeal,
} from './menuComponents'

export function getFilteredComponentsCounts({
  filteredComponents,
}: {
  filteredComponents: MenuComponentsStandardized
}) {
  let numFilteredResults = 0

  filteredComponents.forEach((component) => {
    if (
      component.type !== 'meal' &&
      component.type !== 'mealWithExtra' &&
      component.type !== 'twoMealPicker' &&
      component.type !== 'animatedMealCarousel' &&
      component.type !== 'textImageStack'
    ) {
      return
    }

    if (component.type === 'meal') {
      numFilteredResults = numFilteredResults + 1
    } else if (component.type === 'mealWithExtra') {
      numFilteredResults = numFilteredResults + 1
    } else if (component.type === 'twoMealPicker') {
      numFilteredResults =
        numFilteredResults + component.properties.meals.length
    } else if (component.type === 'animatedMealCarousel') {
      numFilteredResults =
        numFilteredResults + component.properties.mealOptions.length
    } else if (component.type === 'textImageStack') {
      component.properties.children.forEach((child) => {
        if (child.type === 'meal') {
          numFilteredResults = numFilteredResults + 1
        } else if (child.type === 'mealWithExtra') {
          numFilteredResults = numFilteredResults + 1
        } else if (child.type === 'twoMealPicker') {
          numFilteredResults =
            numFilteredResults + child.properties.meals.length
        } else if (child.type === 'animatedMealCarousel') {
          numFilteredResults =
            numFilteredResults + child.properties.mealOptions.length
        }
      })
    }
  })

  return numFilteredResults
}

export function getFilteredAllergenMealSelections({
  mealSelections,
  mealsInFilters,
  selectedMealFilters,
}: {
  mealSelections: MealSelection[]
  mealsInFilters: { key: string; mealsInFilter: number[] }[]
  selectedMealFilters: SelectedMealFilters
}) {
  const selectedKeys = Object.values(selectedMealFilters).flat()
  const selectedAllergenKeys = selectedKeys.filter(
    (key) => typeof key === 'string' && key.includes('allergen')
  )

  const mealIDsInSelectedAllergenFilters = new Set(
    mealsInFilters
      .filter((mealsInFilter) =>
        selectedAllergenKeys.includes(mealsInFilter.key)
      )
      .map((mealsInFilter) => mealsInFilter.mealsInFilter)
      .flat()
  )

  return mealSelections.filter((mealSelection) =>
    mealIDsInSelectedAllergenFilters.has(mealSelection.mealID)
  )
}

export function getFilteredMenuComponents({
  allMealIDs,
  mealFilters,
  mealsInFilters,
  menuComponents,
  selectedMealFilters,
}: {
  allMealIDs: number[]
  mealFilters: MealFilter[]
  mealsInFilters: { key: string; mealsInFilter: number[] }[]
  menuComponents: MenuComponentsStandardized
  selectedMealFilters: SelectedMealFilters
}) {
  const selectedKeys = Object.values(selectedMealFilters).flat()
  const mealIDsInFilters = getMealIDsInFilters(
    selectedMealFilters,
    mealFilters,
    mealsInFilters,
    allMealIDs
  )

  function filterMealComponent(component: MenuComponentStandardizedMeal) {
    const filtered: MenuComponentStandardizedMeal[] = []
    const remaining: MenuComponentStandardizedMeal[] = []

    if (mealIDsInFilters.includes(component.properties.id)) {
      filtered.push(component)
    } else {
      remaining.push(component)
    }

    return {
      filtered,
      remaining,
    }
  }

  function filterMealWithExtraComponent(
    component: MenuComponentStandardizedMealWithExtra
  ) {
    const filtered: MenuComponentStandardizedMealWithExtra[] = []
    const remaining: MenuComponentStandardizedMealWithExtra[] = []

    // We keep the extra paired with the meal so the extra does not show up in "Everything Else" by itself
    // See https://tovala.atlassian.net/browse/WAT-676 for more details
    if (mealIDsInFilters.includes(component.properties.meal.id)) {
      filtered.push(component)
    } else {
      remaining.push(component)
    }

    return {
      filtered,
      remaining,
    }
  }

  function filterTwoMealPickerComponent(
    component: MenuComponentStandardizedTwoMealPicker
  ) {
    const filtered: (
      | MenuComponentStandardizedMeal
      | MenuComponentStandardizedTwoMealPicker
    )[] = []
    const remaining: (
      | MenuComponentStandardizedMeal
      | MenuComponentStandardizedTwoMealPicker
    )[] = []

    const [mealsInFilter, mealsOutsideFilters] = partition(
      component.properties.meals,
      (meal) => mealIDsInFilters.includes(meal.id)
    )

    if (mealsInFilter.length === component.properties.meals.length) {
      filtered.push(component)
    } else if (
      mealsOutsideFilters.length === component.properties.meals.length
    ) {
      remaining.push(component)
    } else {
      mealsInFilter.forEach((meal) => {
        filtered.push(convertTwoMealPickerMealToBaseMeal(meal))
      })

      mealsOutsideFilters.forEach((meal) => {
        remaining.push(convertTwoMealPickerMealToBaseMeal(meal))
      })
    }

    return {
      filtered,
      remaining,
    }
  }

  function filterAnimatedMealCarouselComponent(
    component: MenuComponentStandardizedMealCarousel
  ) {
    const filtered: (
      | MenuComponentStandardizedMeal
      | MenuComponentStandardizedMealCarousel
    )[] = []
    const remaining: (
      | MenuComponentStandardizedMeal
      | MenuComponentStandardizedMealCarousel
    )[] = []

    const [mealsInFilter, mealsOutsideFilters] = partition(
      component.properties.mealOptions,
      (meal) => mealIDsInFilters.includes(meal.id)
    )

    if (mealsInFilter.length === component.properties.mealOptions.length) {
      filtered.push(component)
    } else if (
      mealsOutsideFilters.length === component.properties.mealOptions.length
    ) {
      remaining.push(component)
    } else {
      if (mealsInFilter.length > 1) {
        const newCarousel = cloneDeep(component)
        newCarousel.properties.mealOptions = mealsInFilter

        filtered.push(newCarousel)
      } else {
        filtered.push(convertMealCarouselMealToBaseMeal(mealsInFilter[0]))
      }

      if (mealsOutsideFilters.length > 1) {
        const newCarousel = cloneDeep(component)
        newCarousel.properties.mealOptions = mealsOutsideFilters

        remaining.push(newCarousel)
      } else {
        remaining.push(
          convertMealCarouselMealToBaseMeal(mealsOutsideFilters[0])
        )
      }
    }

    return {
      filtered,
      remaining,
    }
  }

  if (
    mealFilters.length > 0 &&
    selectedKeys.length > 0 &&
    mealIDsInFilters.length > 0
  ) {
    const filteredComponents: MenuComponentsStandardized = []
    const everythingElse: MenuComponentsStandardized = []

    menuComponents.forEach((component) => {
      if (
        component.type !== 'meal' &&
        component.type !== 'mealWithExtra' &&
        component.type !== 'twoMealPicker' &&
        component.type !== 'animatedMealCarousel' &&
        component.type !== 'textImageStack'
      ) {
        filteredComponents.push(component)
        return
      }

      if (component.type === 'meal') {
        const { filtered, remaining } = filterMealComponent(component)

        filteredComponents.push(...filtered)
        everythingElse.push(...remaining)
      } else if (component.type === 'mealWithExtra') {
        const { filtered, remaining } = filterMealWithExtraComponent(component)

        filteredComponents.push(...filtered)
        everythingElse.push(...remaining)
      } else if (component.type === 'twoMealPicker') {
        const { filtered, remaining } = filterTwoMealPickerComponent(component)

        filteredComponents.push(...filtered)
        everythingElse.push(...remaining)
      } else if (component.type === 'animatedMealCarousel') {
        const { filtered, remaining } =
          filterAnimatedMealCarouselComponent(component)

        filteredComponents.push(...filtered)
        everythingElse.push(...remaining)

        return component.properties.mealOptions.some((option) =>
          mealIDsInFilters.includes(option.id)
        )
      } else if (component.type === 'textImageStack') {
        const childrenFilteredComponents: MenuComponentStandardizedTextImageStackChildren =
          []
        const childrenEverythingElse: MenuComponentStandardizedTextImageStackChildren =
          []

        component.properties.children.forEach((child) => {
          if (child.type === 'meal') {
            const { filtered, remaining } = filterMealComponent(child)

            childrenFilteredComponents.push(...filtered)
            childrenEverythingElse.push(...remaining)
          } else if (child.type === 'mealWithExtra') {
            const { filtered, remaining } = filterMealWithExtraComponent(child)

            childrenFilteredComponents.push(...filtered)
            childrenEverythingElse.push(...remaining)
          } else if (child.type === 'twoMealPicker') {
            const { filtered, remaining } = filterTwoMealPickerComponent(child)

            childrenFilteredComponents.push(...filtered)
            childrenEverythingElse.push(...remaining)
          } else if (child.type === 'animatedMealCarousel') {
            const { filtered, remaining } =
              filterAnimatedMealCarouselComponent(child)

            childrenFilteredComponents.push(...filtered)
            childrenEverythingElse.push(...remaining)

            return child.properties.mealOptions.some((option) =>
              mealIDsInFilters.includes(option.id)
            )
          }
        })

        if (childrenFilteredComponents.length) {
          const newTextImageStack = cloneDeep(component)
          newTextImageStack.properties.children = childrenFilteredComponents

          filteredComponents.push(newTextImageStack)
        }
        if (childrenEverythingElse.length) {
          const newTextImageStack = cloneDeep(component)
          newTextImageStack.properties.children = childrenEverythingElse

          everythingElse.push(newTextImageStack)
        }
      }
    })

    return {
      everythingElse,
      filteredComponents,
      mealIDsInFilters,
    }
  }

  return {
    everythingElse: menuComponents,
    filteredComponents: [],
    mealIDsInFilters,
  }
}

export function getMealIDsInFilters<FilterKey extends string>(
  selectedMealFilters: SelectedMealFilters<FilterKey>,
  allMealFilters: MealFilter[],
  mealsInFilters: { key: FilterKey; mealsInFilter: number[] }[],
  allMealIDs: number[]
) {
  let inclusionFilterKeys: FilterKey[] = []
  let exclusionFilterKeys: FilterKey[] = []

  // Our filters are configured in groupings to either include or exclude meals.
  // For example, the "Dietary Preferences" filter group is set up to include
  // meals, so if a user selects the "low_sodium" filter, we'll only return meals
  // that have low sodium. On the other hand, the "Allergens" filter group is set
  // up to exclude meals, so if a user selects the "allergen_eggs" filter, we'll
  // exclude meals that have an eggs allergen (i.e. the user is looking for meals
  // they won't have an allergic reaction to).
  for (const [key, value] of Object.entries(selectedMealFilters)) {
    if (typeof value === 'boolean') {
      continue
    }

    const filterGroup = allMealFilters.find(
      (filter) => filter.displayString === key
    )

    if (filterGroup && filterGroup.resultsIncluded) {
      inclusionFilterKeys = [...inclusionFilterKeys, ...value]
    } else {
      exclusionFilterKeys = [...exclusionFilterKeys, ...value]
    }
  }

  let includeMealIDs = [...allMealIDs]

  if (inclusionFilterKeys.length) {
    // If any include filters are selected, get the meals that are in ALL filters (as opposed to ANY).
    const includeMealsInFilters = getMealsInFilters(
      inclusionFilterKeys,
      mealsInFilters
    )
    includeMealIDs = intersection(...includeMealsInFilters)
  }

  const excludeMealsInFilters = getMealsInFilters(
    exclusionFilterKeys,
    mealsInFilters
  )
  const excludeMealIDs = new Set(excludeMealsInFilters.flat())

  return includeMealIDs.filter((mealID) => !excludeMealIDs.has(mealID))
}

function getMealsInFilters<FilterKey extends string>(
  filters: FilterKey[],
  menuFilters: { key: string; mealsInFilter: number[] }[]
) {
  return filters.map((key) => {
    const selectedFilter = menuFilters.find((filter) => filter.key === key)

    return selectedFilter?.mealsInFilter ?? []
  })
}
