const CartIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.33325 11.6667H25.6666L23.1183 23.5588C22.8548 24.7883 21.7682 25.6667 20.5108 25.6667H11.489C10.2316 25.6667 9.14502 24.7883 8.88155 23.5588L6.33325 11.6667Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M10.6667 11.3333L12.3334 6.33331"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M21.3334 11.3333L19.6667 6.33331"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default CartIcon
