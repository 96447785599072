import { createContext, ReactNode, useContext } from 'react'
import {
  FeatureName,
  Features as CDNFeatures,
  useFeatures as useCDNFeatures,
} from '@tovala/browser-apis-cdn'

interface Features {
  defaultUIMealWithOneSwap: 'mealWithExtra' | 'twoMealPicker'
  showSurchargePrompt: boolean
  userRegistrationInAppFlow: boolean
  showStaticSkipAsNoMenu: boolean
}

const FeaturesContext = createContext<Features | undefined>(undefined)

function getIsFeatureEnabled({
  name,
  features,
}: {
  name: FeatureName
  features: CDNFeatures | undefined
}) {
  const feature = features?.featureFlags.find(({ feature }) => feature === name)

  return feature?.platforms.web ?? false
}

const FeaturesProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const { data: features } = useCDNFeatures()

  return (
    <FeaturesContext.Provider
      value={{
        defaultUIMealWithOneSwap: getIsFeatureEnabled({
          name: 'defaultToMealExtraComponent',
          features,
        })
          ? 'mealWithExtra'
          : 'twoMealPicker',
        showSurchargePrompt: getIsFeatureEnabled({
          name: 'surchargePrompt',
          features,
        }),
        userRegistrationInAppFlow: getIsFeatureEnabled({
          name: 'userRegistrationInAppFlow',
          features,
        }),
        showStaticSkipAsNoMenu: getIsFeatureEnabled({
          name: 'showStaticSkipAsNoMenu',
          features,
        }),
      }}
    >
      {children}
    </FeaturesContext.Provider>
  )
}

function useFeatures() {
  const context = useContext(FeaturesContext)
  if (context === undefined) {
    throw new Error('useFeatures must be used in an FeaturesProvider')
  }

  return context
}

export { FeaturesProvider, useFeatures }
