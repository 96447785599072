import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import jwtDecode from 'jwt-decode'
import { JWTPayload, useUserV1, UserV1 } from '@tovala/browser-apis-combinedapi'

import {
  identifyUser,
  identifyUserFromJWT,
  IdentifyUserProperties,
} from 'utils/analytics'
import { isDateBefore } from 'utils/dates'
import { getCookie, removeCookie, setCookie } from 'utils/storage'
import { useOvenChannel } from 'hooks/ovenChannel'

import { getEnvVar } from 'utils/env'

interface Auth {
  hadUnauthEvent: boolean
  isLoadingUser: boolean
  isLoggedIn: boolean
  loadUserError: Error | null
  onJWTChanged(newJWT: string | null): void
  user: UserV1 | undefined
}

const AuthContext = createContext<Auth | undefined>(undefined)

const UNAUTHORIZED_EVENT_NAME = 'mytovala:unauthorized'

export function dispatchUnauthorizedEvent() {
  const unauthorizedEvent = new Event(UNAUTHORIZED_EVENT_NAME)
  document.dispatchEvent(unauthorizedEvent)
}

function getJWTExpirationDate(decodedJWT: JWTPayload) {
  return new Date(decodedJWT.exp * 1000)
}

const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [hadUnauthEvent, setHadUnauthEvent] = useState(false)
  const [jwt, setJwt] = useState<string | null>(() => {
    const jwt = getCookie('JWT_TOKEN') ?? null
    if (!jwt) {
      return null
    }

    const decodedJWT = jwtDecode<JWTPayload>(jwt)
    const isExpired = isDateBefore(getJWTExpirationDate(decodedJWT), new Date())

    return isExpired ? null : jwt
  })
  const [userID, setUserID] = useState<number | undefined>()

  const {
    data: user,
    error: loadUserError,
    isError: hasLoadUserError,
    isLoading: isLoadingUser,
  } = useUserV1({ userID })

  const { data: ovenChannelData, isLoading: isLoadingOvenChannel } =
    useOvenChannel({ userID })
  const { ovenChannel, ovenSubChannel } = ovenChannelData

  useEffect(() => {
    manageCookieForJWT(jwt)

    if (jwt) {
      const decodedJWT = jwtDecode<JWTPayload>(jwt)

      setUserID(decodedJWT.userId)

      if (!isLoadingOvenChannel) {
        const userProperties: IdentifyUserProperties = {
          ...(ovenChannel && { ovenChannel }),
          ...(ovenSubChannel && { ovenSubChannel }),
        }

        identifyUserFromJWT({ decodedJWT, userProperties })
      }
    } else {
      setUserID(undefined)
    }
  }, [jwt, isLoadingOvenChannel, ovenChannel, ovenSubChannel])

  useEffect(() => {
    function handleUnauthorizedEvent() {
      setHadUnauthEvent(true)
      setJwt(null)
    }

    document.addEventListener(UNAUTHORIZED_EVENT_NAME, handleUnauthorizedEvent)

    return () => {
      document.removeEventListener(
        UNAUTHORIZED_EVENT_NAME,
        handleUnauthorizedEvent
      )
    }
  }, [])

  useEffect(() => {
    if (user && !isLoadingOvenChannel) {
      const userProperties: IdentifyUserProperties = {
        ...(ovenChannel && { ovenChannel }),
        ...(ovenSubChannel && { ovenSubChannel }),
      }

      identifyUser({ user, userProperties })
    }
  }, [user, isLoadingOvenChannel, ovenChannel, ovenSubChannel])

  return (
    <AuthContext.Provider
      value={{
        hadUnauthEvent,
        isLoadingUser: !!userID && isLoadingUser,
        isLoggedIn: !!jwt,
        loadUserError: hasLoadUserError ? loadUserError : null,
        onJWTChanged: (newJWT: string | null) => {
          manageCookieForJWT(newJWT)
          setJwt(newJWT)

          if (newJWT) {
            setHadUnauthEvent(false)
          }
        },
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function manageCookieForJWT(jwt: string | null) {
  const cookiePathAndDomain = {
    domain:
      // develop-preview is a APP_ENV tied to the my.dev.tovala.com domain and production is my.tovala.com.
      // On other envs (like Vercel preview envs), we don't know what the exact domain will be so we don't
      // explicitly set it.
      getEnvVar('APP_ENV') === 'develop-preview' ||
      getEnvVar('APP_ENV') === 'production'
        ? 'tovala.com'
        : undefined,
    path: '/',
  }

  if (jwt) {
    const decodedJWT = jwtDecode<JWTPayload>(jwt)

    setCookie({
      content: jwt,
      cookieName: 'JWT_TOKEN',
      options: {
        ...cookiePathAndDomain,
        expires: getJWTExpirationDate(decodedJWT),
        sameSite: 'lax',
      },
    })
  } else {
    removeCookie('JWT_TOKEN', cookiePathAndDomain)
  }
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used in an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
