import { MealSummary, MealTag } from '@tovala/browser-apis-combinedapi'
import { MenuMeal } from '@tovala/browser-apis-cdn'

import { getDisplayTags, getMealImageURL, getMealSurcharge } from './meals'
import {
  MenuComponentCommonMealProperties,
  MenuComponentStandardizedImageTag,
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedMealCarouselMealOption,
  MenuComponentStandardizedTwoMealPickerMeal,
} from '@tovala/browser-apis-menu-components'

export function convertMealCarouselMealToBaseMeal(
  meal: MenuComponentStandardizedMealCarouselMealOption
): MenuComponentStandardizedMeal {
  return {
    id: `meal-${meal.id}`,
    properties: {
      id: meal.id,
      image: meal.image,
      mealSummary: meal.mealSummary,
      subtitle: meal.subtitle,
      surcharge: meal.surcharge,
      tags: meal.tags,
      title: meal.title,
    },
    type: 'meal',
  }
}

export function convertTwoMealPickerMealToBaseMeal(
  meal: MenuComponentStandardizedTwoMealPickerMeal
): MenuComponentStandardizedMeal {
  return {
    id: `meal-${meal.id}`,
    properties: {
      id: meal.id,
      image: meal.image,
      imageTag: meal.imageTag,
      mealSummary: meal.mealSummary,
      subtitle: meal.subtitle,
      surcharge: meal.surcharge,
      tags: meal.tags,
      title: meal.title,
    },
    type: 'meal',
  }
}

export function getCommonMealProperties({
  meal,
  overrides,
  suggestions,
}: {
  meal: MealSummary
  overrides?: MenuMeal
  suggestions: MealSummary[]
}): MenuComponentCommonMealProperties {
  return {
    id: meal.id,
    image: {
      url: overrides?.image?.url ?? getMealImageURL(meal),
    },
    mealSummary: meal,
    subtitle: overrides?.subtitle ?? meal.subtitle,
    surcharge: getMealSurcharge({ meal }),
    tags: overrides?.displayTags
      ? overrides.displayTags.map(({ title, titleCollapsed }) => {
          if (titleCollapsed) {
            return { titleCollapsed, titleExpanded: title }
          }

          return title
        })
      : getDisplayTags({ meal, suggestions }),
    title: overrides?.title ?? meal.title,
  }
}

export function getStandardizedImageTag({
  meal,
  overrides,
}: {
  meal: { tags: MealTag[] }
  overrides?: MenuMeal
}): MenuComponentStandardizedImageTag | undefined {
  const bannerTag = meal.tags.find((tag) =>
    [
      'meal_banner_top_leading',
      'meal_banner_top_center',
      'meal_banner_top_trailing',
      'meal_banner_top_jumbo',
    ].includes(tag.displayMode)
  )

  return overrides?.imageFlair
    ? {
        icon: overrides.imageFlair.icon?.url,
        title: overrides.imageFlair.title,
      }
    : bannerTag
    ? { icon: bannerTag.image, title: bannerTag.title }
    : undefined
}
